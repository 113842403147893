import Pagination from 'src/components/displays/pagination/Pagination';

import EmptyTransaction from 'src/components/displays/empty/EmptyTransaction';
import { selectAuth } from 'src/store/auth/authSlice';
import { useAppSelector, useAppDispatch } from 'src/store/hook';
import _ from 'lodash';
import { useEffect } from 'react';
import { selectInventory, fetchInventoryNftItems } from 'src/store/nft-item/inventorySlice';
import ListHeroes from 'src/pages/components/list-heroes/ListHeroes';

const TabHeroes = ({ loadingSync }) => {
  const auth = useAppSelector(selectAuth);
  const inventory = useAppSelector(selectInventory);
  const dispatch = useAppDispatch();

  const handleGetNttItems = (address: string) => {
    dispatch(fetchInventoryNftItems({ owner: address }));
  };

  useEffect(() => {
    if (auth.address) {
      handleGetNttItems(auth.address);
    }
  }, [auth.address, loadingSync]);

  if (_.isEmpty(inventory.items)) {
    return <EmptyTransaction content="No results found" />;
  }

  return (
    <>
      <ListHeroes items={inventory.items} />
      <Pagination size={inventory.pages} className="mt-10" />
    </>
  );
};

export default TabHeroes;
