import { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import classNames from 'classnames';

interface FilterPriceProps {
  className?: string;
  isExpand?: boolean;
  name: string;
  callback?: () => void;
}

const defaultProps = {
  isExpand: false,
};

const FilterPrice = ({ className, isExpand, name, callback }: FilterPriceProps) => {
  const method = useFormContext();
  const watchMinPrice = useWatch({ control: method.control, name: 'minPrice' });
  const watchMaxPrice = useWatch({ control: method.control, name: 'maxPrice' });
  const [expand, setExpand] = useState(isExpand);
  const { onChange: onChangeMinPrice, ...restMin } = method.register('minPrice');
  const { onChange: onChangeMaxPrice, ...restMax } = method.register('maxPrice');
  const priceClassName = classNames('price-container', className);
  const iconClassname = classNames('icon', { 'icon-plus': !expand }, { 'icon-minus': expand });
  const contentContainerClassname = classNames('content-container', { expand: expand });

  const handleExpand = () => {
    setExpand(!expand);
  };

  const handleChangeMinPrice = (e) => {
    method.setValue('minPrice', e.target.value);
    onChangeMinPrice(e);

    if (callback) {
      callback();
    }
  };

  const handleChangeMaxPrice = (e) => {
    method.setValue('maxPrice', e.target.value);
    onChangeMaxPrice(e);

    if (callback) {
      callback();
    }
  };

  return (
    <div className={priceClassName}>
      <div className="title-container" onClick={handleExpand}>
        <span>PRICE (BNB)</span>
        <div className={iconClassname} />
      </div>
      <div className={contentContainerClassname}>
        <input {...restMin} className="min-container" defaultValue={watchMinPrice} onChange={handleChangeMinPrice} />
        <input {...restMax} className="max-container" defaultValue={watchMinPrice} onChange={handleChangeMaxPrice} />
      </div>
    </div>
  );
};

FilterPrice.displayName = 'FilterPrice';
FilterPrice.defaultProps = defaultProps;

export default FilterPrice;
