import _ from 'lodash';
import { useMemo } from 'react';
import { DataTable } from 'src/components/displays';
import { Container } from 'src/components/layouts';
import { ModalBuyItems } from 'src/components/panels';

const content =
  "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.";

export type ItemDetailsProps = {
  type?: 'gear' | 'hero';
};

const ItemDetails = ({ type = 'gear' }: ItemDetailsProps) => {
  const data = useMemo(
    () => _.times(10, (i) => ({ id: i, time: '25 Oct 2021 10:03', amount: '0.12340000 BUSD', from: '094373474873724890', to: '094373474873724890' })),
    [],
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Time',
        accessor: 'time',
      },
      {
        Header: 'Amount',
        accessor: 'amount',
      },
      {
        Header: 'From',
        accessor: 'from',
      },
      {
        Header: 'To',
        accessor: 'to',
      },
    ],
    [],
  );

  return (
    <Container className="item-details">
      <ModalBuyItems subtitle="Description" type={type} content={content} closable={false} />
      <DataTable title="Sale History" sortable data={data} columns={columns} className="my-32 max-w-screen-lg" />
    </Container>
  );
};

export default ItemDetails;
