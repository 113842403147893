import { FC, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import Header from 'src/components/layouts/header/Header';
import Footer from 'src/components/layouts/footer/Footer';

import { Modal } from './components/panels';
import Warning from './components/warning/Warning';

import useUnload from './hooks/useUnload';

import Demo from './pages/demo/Demo';
import Home from './pages/dashboard/Dashboard';
import Inventory from './pages/inventory/Inventory';
import Buy from './pages/buy/Buy';
import Marketplace from './pages/marketplace/Marketplace';
import ItemDetails from './pages/details/ItemDetails';

import { useAppDispatch, useAppSelector } from './store/hook';
import { hideModal, MODAL_TYPE, showModal } from './store/modal/modalSlice';
import { selectAuth, logout } from './store/auth/authSlice';
import { getSeasonInfos } from './store/season/seasonApi';

import { isDevEnv } from './env';

const renderBody = (isLoggedIn: boolean) => {
  const innerWidth = window.innerWidth;

  // TODO: Should re-check this behaviour
  if (innerWidth <= 1000) {
    return <Warning />;
  }

  return (
    <div className="bg-main">
      <Header disabled={!isLoggedIn} />
      <div className="content">
        <Routes>
          <Route path="/" element={<Home />} />
          {isLoggedIn && (
            <>
              <Route path="buy" element={<Buy />} />
              <Route path="marketplace" element={<Marketplace />} />
              <Route path="marketplace/:id" element={<ItemDetails />} />
              <Route path="inventory" element={<Inventory />} />
              <Route path="inventory/:id" element={<ItemDetails />} />
              {isDevEnv && <Route path="demo" element={<Demo />} />}
            </>
          )}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
      <Footer disabled={!isLoggedIn} />
    </div>
  );
};

declare let window: any;
const { ethereum } = window;

const App: FC = () => {
  const dispatch = useAppDispatch();
  const { loggedIn } = useAppSelector(selectAuth);

  useUnload(() => {
    dispatch(hideModal());
  });

  useEffect(() => {
    if (ethereum === undefined) {
      dispatch(showModal({ type: MODAL_TYPE.FAILED, data: { closable: false, title: 'Please install Metamask', subtitle: 'https://metamask.io/download' } }));
      return;
    }

    getSeasonInfos();

    // Detect if user changes wallet net or change account
    ethereum.on('accountsChanged', () => {
      dispatch(logout());
      window.location.reload();
    });
    ethereum.on('chainChanged', () => {
      window.location.reload();
    });

    if (!loggedIn) {
      dispatch(showModal({ type: MODAL_TYPE.LOGIN, data: { closable: false } }));
      return;
    }
  }, [ethereum, loggedIn]);

  const isLoggedIn = ethereum !== undefined && loggedIn;

  return (
    <Router>
      <ToastContainer />
      {renderBody(isLoggedIn)}
      <Modal overlay closable />
    </Router>
  );
};

export default App;
