import classNames from 'classnames';
import { shorten } from 'src/utils/general';
import ButtonCopy from 'src/components/buttons/button-copy/ButtonCopy';

interface WalletDetailProps {
  className?: string;
  balance: string;
  address: string;
}

const WalletDetail = ({ className, balance, address, ...props }: WalletDetailProps) => {
  const walletDetailContainerClassName = classNames('wallet-detail-container', className);

  return (
    <div className={walletDetailContainerClassName} {...props}>
      <div className="address-container">
        <span className="address">{shorten(address, 15, 2)}</span>
        <ButtonCopy address={address} />
      </div>
      <div className="balance-container">
        <span className="title">Balance</span>
        <span className="currency-1">{balance}</span>
        <span className="currency-2">22,404.86 USD</span>
      </div>
    </div>
  );
};

WalletDetail.displayName = 'WalletDetail';

export default WalletDetail;
