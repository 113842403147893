import { createContext, useContext } from 'react';

export type MenuType = 'default' | 'button' | 'header';

export interface MenuContextProps {
  type: MenuType;
  activeItem: string;
  onClick: (e: any, item: string) => void;
}

const MenuContext = createContext<MenuContextProps | undefined>(undefined);

export const useMenu = (): MenuContextProps => {
  const context = useContext(MenuContext);

  if (!context) {
    throw new Error('This component must be used within a <Menu> component.');
  }

  return context;
};

export default MenuContext;
