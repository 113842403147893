import classNames from 'classnames';
import { useAppDispatch } from 'src/store/hook';
import { hideModal } from 'src/store/modal/modalSlice';
import { Button, ButtonBack } from 'src/components/buttons';
import { ButtonColor } from 'src/components/buttons/Button';
import { Alert } from 'src/components/displays';

import ModalContent, { ModalContentProps } from './ModalContent';
import { ReactNode } from 'react';

export type ModalBtnType = {
  content: string;
  color: ButtonColor;
  compact?: boolean;
  className?: string;
  onClick?: (e: any) => void;
  type?: 'button' | 'submit' | 'reset' | undefined;
};
export type ModalSize = 'large' | 'small';
export type ModalVariant = 'info' | 'error';
export type ModalProps = {
  variant?: ModalVariant;
  size?: ModalSize;
  title?: ReactNode;
  bannerUrl?: string;
  itemUrl?: string;
  closable?: boolean;
  backable?: boolean;
  error?: { message: string };
  btns?: ModalBtnType[];
  className?: string;
} & ModalContentProps;

const defaultProps = { variant: 'info', size: 'small', backable: false, closable: false, overlay: false };

const ModalBase = ({ size, variant, title, bannerUrl, itemUrl, closable, backable, btns, error, className, ...props }: ModalProps) => {
  const modalClassName = classNames('modal', size, variant, className);
  const modalAlertClassName = classNames({ 'mt-14': size === 'large', 'mt-0': size === 'small' });

  const dispatch = useAppDispatch();

  const onCloseModal = () => {
    dispatch(hideModal());
  };

  const renderTitle = () => {
    if (typeof title === 'string') {
      return <h1 className="modal-title">{title}</h1>;
    }
    return <div className="modal-title">{title}</div>;
  };

  return (
    <div className="modal-container">
      {backable && <ButtonBack />}
      <div className={modalClassName}>
        <div className="modal-layout">
          {closable && <span className="icon-close" onClick={onCloseModal} />}
          {bannerUrl && <img className="modal-banner" src={bannerUrl} alt="Processing" />}
          {renderTitle()}
          {itemUrl ? (
            <div className="modal-info-layout">
              <img className="modal-img-item" src={itemUrl} alt={'Event Box'} />
              <div className="modal-info modal-subtitle-left">
                <ModalContent {...props} />
                {error && <Alert type="error" content={error.message} className={modalAlertClassName} />}
              </div>
            </div>
          ) : (
            <ModalContent {...props} />
          )}
          {btns && (
            <div className="modal-btn-actions mt-8">
              {btns.map(({ content, color, onClick, compact, className, type }, index) => (
                <Button key={index} color={color} content={content} onClick={onClick} className={className} compact={compact} type={type} />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

ModalBase.defaultProps = defaultProps;
ModalBase.displayName = 'ModalBase';

export default ModalBase;
