import { FC } from 'react';

interface ProgressBarProps {
  content?: string;
  type: 'info' | 'success' | 'error';
  className?: string;
  value: number;
  maxValue: number;
  minValue?: number;
}

const calculatePercentage = (value: number, total: number) => {
  return (value / total) * 100;
};

const kConvert = (value: number) => {
  return value < 1000 ? value : `${value / 1000}K`;
};

const ProgressBar: FC<ProgressBarProps> = ({ content, value, minValue = 0, maxValue, type, className }) => {
  const progressType = `progress-${type}`;

  const progressPercentage = calculatePercentage(value, maxValue - minValue);

  return (
    <div className={`flex items-center justify-items-center ${className}`}>
      <div className={`progress-wrapper`}>
        <div className={`progress-bar ${progressType}`}>
          <div className="progress-line" />
          <div style={{ width: `${progressPercentage}%` }} className={`progress ${progressType}`} />
        </div>
        {content && <div className="progress-title">{content}</div>}
      </div>
      <div className="progress-value">{kConvert(maxValue)}</div>
    </div>
  );
};

export default ProgressBar;
