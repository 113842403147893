import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

import logo from 'src/assets/logos/idle-glory.png';

interface FooterProps {
  disabled?: boolean;
  className?: string;
}

const defaultProps = { disabled: false, className: '' };

const Footer = ({ disabled, className }: FooterProps) => {
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate('/');
  };

  const onClickDisable = (e: any) => {
    e.preventDefault();
  };

  return (
    <div className={`footer ${className}`}>
      <NavLink className="disabled" to="/contact" onClick={onClickDisable}>
        Contact
      </NavLink>
      <NavLink className="disabled" to="/help" onClick={onClickDisable}>
        Get help
      </NavLink>
      <img alt="Idle Glory" src={logo} onClick={handleLogoClick} />
      <NavLink className="disabled" to="/marketplace" onClick={onClickDisable}>
        Marketplace
      </NavLink>
      <NavLink className="disabled" to="/buy-token" onClick={onClickDisable}>
        Buy token
      </NavLink>
    </div>
  );
};

Footer.defaultProps = defaultProps;

export default Footer;
