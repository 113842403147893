import { BOX_API_URL } from 'src/env';
import { client } from 'src/utils/api';
import { BoxesModel } from './box.model';

const clientBox = client(BOX_API_URL);

const fetchBoxes = (params): Promise<BoxesModel> => clientBox.get(`/boxes`, { params });
const syncBoxData = () => clientBox.post(`/boxes/data-sync`);

const boxApi = { fetchBoxes, syncBoxData };

export default boxApi;
