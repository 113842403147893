import { ReactNode } from 'react';

import MenuItemButton from './components/MenuItemButton';
import MenuItemDefault from './components/MenuItemDefault';

export interface MenuItemComponentProps {
  active: boolean;
  content?: string;
  onClick: (e: any) => void;
  className?: string;
  children: ReactNode;
  disabled?: boolean;
}

export const menuItemComponents = (props: MenuItemComponentProps) => ({
  default: <MenuItemDefault {...props} />,
  button: <MenuItemButton {...props} />,
});
