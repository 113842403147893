import classNames from 'classnames';

interface SkillProps {
  className?: string;
  src: string;
}

const Skill = ({ className, src, ...props }: SkillProps) => {
  const skillContainerClassName = classNames('skill-container', className);

  return (
    <div className={skillContainerClassName} {...props}>
      <img src={src} alt="Idle Glory" />
    </div>
  );
};

Skill.displayName = 'Skill';

export default Skill;
