import { Form } from 'src/components/form';
import { useAppDispatch, useAppSelector } from 'src/store/hook';
import { MODAL_TYPE, showModal } from 'src/store/modal/modalSlice';
import { TOKEN } from 'src/env';
import { selectAuth } from 'src/store/auth/authSlice';
import FormAlert from 'src/components/form/components/FormAlert';
import * as yup from 'yup';
import BuyButton from 'src/components/buttons/BuyButton';

export type FormValues = {
  amount: number;
};

const defaultValues = { amount: 0 };

const TOKEN_NAME = TOKEN.name;

const FormBuyItems = () => {
  const dispatch = useAppDispatch();
  const { loggedIn } = useAppSelector(selectAuth);

  const schema = yup.object({
    amount: yup.number().integer().required('Required').min(0, 'The number of boxes cannot be smaller than 0'),
  });

  const onSubmit = async () => {
    if (!loggedIn) {
      dispatch(showModal({ type: MODAL_TYPE.LOGIN }));
      return;
    }

    dispatch(showModal({ type: MODAL_TYPE.SET_PRICE }));
  };

  return (
    <Form<FormValues> onSubmit={onSubmit} defaultValues={defaultValues} schema={schema}>
      <BuyButton content="Buy Now" className="modal-item-btn-buy" />
      <FormAlert name="amount" messages={{ notEnough: `Your balance is not enough ${TOKEN_NAME}` }} />
    </Form>
  );
};

export default FormBuyItems;
