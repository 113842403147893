import { useState } from 'react';
import { useFormContext, useWatch, Controller } from 'react-hook-form';
import classNames from 'classnames';
import Slider from 'rc-slider';

interface RangeDetailProps {
  content: string;
}

interface FilterRangeProps {
  content: string;
  range: RangeDetailProps[];
  className?: string;
  isExpand?: boolean;
  name: string;
  callback: () => void;
}

const defaultProps = {
  content: 'Quality',
  isExpand: false,
  range: ['C', 'B', 'A', 'S', 'SS'],
};

const FilterRange = ({ content, range, className, isExpand, name, callback }: FilterRangeProps) => {
  const method = useFormContext();
  const watchValue = useWatch({ control: method.control, name });
  const [expand, setExpand] = useState(isExpand);
  const rangeClassName = classNames('range-container', className);
  const iconClassname = classNames('icon', { 'icon-plus': !expand }, { 'icon-minus': expand });
  const contentContainerClassname = classNames('content-container', { expand: expand });

  const marks = Object.assign({}, range);

  const handleExpand = () => {
    setExpand(!expand);
  };

  return (
    <div className={rangeClassName}>
      <div className="title-container" onClick={handleExpand}>
        <span>{content}</span>
        <div className={iconClassname} />
      </div>
      <div className={contentContainerClassname}>
        <Controller
          name={name}
          control={method.control}
          render={({ field }) => {
            const handleChange = (value) => {
              method.setValue(name, value);
              if (callback) {
                callback();
              }
            };

            return <Slider {...field} min={0} marks={marks} onChange={handleChange} defaultValue={watchValue} step={null} max={range.length - 1} />;
          }}
        />
      </div>
    </div>
  );
};

FilterRange.displayName = 'FilterRange';
FilterRange.defaultProps = defaultProps;

export default FilterRange;
