import { useAppSelector } from 'src/store/hook';
import { MODAL_TYPE, selectModal } from 'src/store/modal/modalSlice';

import ModalCheckout from './layouts/checkout/ModalCheckout';
import ModalProcessing from './layouts/processing/ModalProcessing';
import ModalTermsOfUse from './layouts/terms-of-use/ModalTermsOfUse';
import ModalFailed from './layouts/failed/ModalFailed';
import ModalLogin from './layouts/login/ModalLogin';
import ModalCompleted from './layouts/completed/ModalCompleted';
import ModalSetPrice from './layouts/set-price/ModalSetPrice';

const ModalLayouts = {
  [MODAL_TYPE.LOGIN]: ModalLogin,
  [MODAL_TYPE.CHECKOUT]: ModalCheckout,
  [MODAL_TYPE.PROCESSING]: ModalProcessing,
  [MODAL_TYPE.COMPLETED]: ModalCompleted,
  [MODAL_TYPE.FAILED]: ModalFailed,
  [MODAL_TYPE.TERMS_OF_USE]: ModalTermsOfUse,
  [MODAL_TYPE.SET_PRICE]: ModalSetPrice,
};

const Modal = (props) => {
  const { type, visible } = useAppSelector(selectModal);

  if (!visible) {
    return null;
  }

  const ModalSpecific = ModalLayouts[type];

  return (
    <div className="modal-overlay">
      <ModalSpecific {...props} />
    </div>
  );
};

Modal.displayName = 'Modal';

export default Modal;
