import { useFormContext } from 'react-hook-form';
import { Alert } from 'src/components/displays';

interface FormAlertProps {
  name: string;
  messages: Record<string, string>;
}

const FormAlert = ({ name, messages }: FormAlertProps) => {
  const {
    formState: { errors },
  } = useFormContext();

  if (!errors[name]) {
    return null;
  }

  const content = messages[errors[name].type] || errors[name].message;

  return <Alert type="error" content={content} className="mt-14" />;
};

export default FormAlert;
