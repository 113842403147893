import { FC } from 'react';

export interface TabPanelProps {
  activeItem: string;
  index: string;
  className?: string;
}

const defaultProps = { className: '' };

const TabPanel: FC<TabPanelProps> = ({ activeItem, index, className, children }) => {
  const baseClassName = 'tab-panel';
  const finalClassName = className ? `${baseClassName} ${className}` : baseClassName;

  return activeItem === index ? <div className={finalClassName}>{children}</div> : null;
};

TabPanel.defaultProps = defaultProps;

export default TabPanel;
