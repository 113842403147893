import imgBannerFailed from 'src/assets/banners/banner-failed.png';
import { useAppDispatch, useAppSelector } from 'src/store/hook';
import { hideModal, selectModal } from 'src/store/modal/modalSlice';
import ModalBase from '../../components/ModalBase';

const DEFAULT_TITLE = 'Failed!';

const ModalFailed = () => {
  const dispatch = useAppDispatch();
  const { data } = useAppSelector(selectModal);

  const title = (data?.title as string) || DEFAULT_TITLE;
  const subtitle = data?.subtitle as string;

  const closable = data ? data.closable === undefined : true;

  const onCloseModal = () => {
    dispatch(hideModal());
  };

  return (
    <ModalBase
      closable={closable}
      variant="error"
      title={title}
      subtitle={subtitle}
      bannerUrl={imgBannerFailed}
      btns={closable ? [{ content: 'Close', color: 'secondary', onClick: onCloseModal }] : []}
    />
  );
};

ModalFailed.displayName = 'ModalFailed';

export default ModalFailed;
