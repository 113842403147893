import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { useFormContext, Controller } from 'react-hook-form';

interface OutlinedInputFieldProps {
  className?: string;
  name: string;
  callback?: () => void;
}

const OutlinedInputField = ({ className, name, callback }: OutlinedInputFieldProps) => {
  const method = useFormContext();
  const buttonClassName = classNames('input-outlined', className);
  const { onChange, ...rest } = method.register(name);
  const onTextChange = debounce((event) => {
    onChange(event);
    if (callback) {
      callback();
    }
  }, 700);

  return <input {...rest} className={buttonClassName} type="text" placeholder="Search in market" onChange={onTextChange} />;
};

OutlinedInputField.displayName = 'OutlinedInputField';

export default OutlinedInputField;
