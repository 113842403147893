import Button from 'src/components/buttons/Button';
import boxLogo from 'src/assets/units/box.png';
import { MODAL_TYPE, showModal } from 'src/store/modal/modalSlice';
import { useAppDispatch } from 'src/store/hook';
import classNames from 'classnames';

interface ModalButtonProps {
  type?: MODAL_TYPE;
  content?: string;
  className?: string;
}

const defaultProps = { content: '' };

const ModalButton = ({ type, content, className }: ModalButtonProps) => {
  const dispatch = useAppDispatch();

  const modalBtnClassName = classNames('btn-modal', className);

  const handleOpenModal = (e: any) => {
    e.preventDefault();
    if (type) {
      dispatch(showModal({ type }));
    }
  };

  return (
    <Button content="Open Modal" className={modalBtnClassName} onClick={handleOpenModal}>
      <div className="btn-modal-header">
        <img alt="Idle Glory" src={boxLogo} />
      </div>
      <div className="btn-modal-footer">
        <h6>{content}</h6>
      </div>
    </Button>
  );
};

ModalButton.displayName = 'ModalButton';
ModalButton.defaultProps = defaultProps;

export default ModalButton;
