import { FC } from 'react';
import { shorten } from 'src/utils/general';
import ButtonCopy from 'src/components/buttons/button-copy/ButtonCopy';

interface CardInfoProps {
  id: string | null;
  name?: string;
  imgUrl: string;
}

const CardInfo: FC<CardInfoProps> = ({ id, name, imgUrl }) => {
  if (id) {
    id = shorten(id);
  }

  return (
    <figure className="card-info">
      <div className="card-img">
        <img src={imgUrl} alt="Unit" />
      </div>
      <div className="card-caption">
        {name && <div className="card-caption-header">{name}</div>}

        <div className="card-caption-subheader">
          <span>{id}</span>
          {id && <ButtonCopy address={id} />}
        </div>
      </div>
    </figure>
  );
};

export default CardInfo;
