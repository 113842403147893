import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { FC } from 'react';
import OutlinedButton from 'src/components/buttons/OutlinedButton';
import { shorten } from 'src/utils/general';
import { logout } from 'src/store/auth/authSlice';
import { useAppDispatch } from 'src/store/hook';
import useClickOutside from 'src/hooks/useClickOutside';

interface HeaderInfoProps {
  // coin1: number;
  balance: number;
  userId: string;
  className?: string;
}

const HeaderInfo: FC<HeaderInfoProps> = ({ balance, userId, className }) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useClickOutside(false);
  const headerInfoClassName = classNames('header-info', className);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleHeaderInfoClick = (e) => {
    e.preventDefault();
    setIsComponentVisible(!isComponentVisible);
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate('/');
  };

  return (
    <div className={headerInfoClassName} ref={ref}>
      <div className="header-info-container" onClick={handleHeaderInfoClick}>
        <div className="header-info-item">
          <span>{balance}</span>
          <span className="bitcoin" />
        </div>
        {/* <div className="header-info-item">
          <span>{coin2}</span>
          <span className="bitcoin" />
        </div> */}
        <div className="header-info-item">{shorten(userId)}</div>
      </div>
      {isComponentVisible && (
        <div className="logout-container">
          <OutlinedButton className="btn-logout" content="Logout" onClick={handleLogout} />
        </div>
      )}
    </div>
  );
};

export default HeaderInfo;
