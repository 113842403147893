import { Children, createElement, isValidElement, ReactNode } from 'react';
import { useForm, SubmitHandler, UnpackNestedValue, DeepPartial, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

type FormProps<TFormValues> = {
  defaultValues: UnpackNestedValue<DeepPartial<TFormValues>> | undefined;
  onSubmit: SubmitHandler<TFormValues>;
  schema?: yup.AnyObjectSchema;
  className?: string;
  children: ReactNode;
};

interface EnrichedChildren {
  name: string;
  children?: ReactNode;
}

const Form = <TFormValues extends Record<string, any> = Record<string, any>>({
  defaultValues,
  onSubmit,
  schema,
  className,
  children,
}: FormProps<TFormValues>) => {
  const methods = useForm<TFormValues>({ defaultValues, resolver: schema && yupResolver(schema) });

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className={className}>
        {Children.map(children, (child) => {
          if (!isValidElement<EnrichedChildren>(child)) {
            return child;
          }

          return child.props.name
            ? createElement(child.type, {
                ...{
                  ...child.props,
                  key: child.props.name,
                },
              })
            : child;
        })}
      </form>
    </FormProvider>
  );
};

export default Form;
