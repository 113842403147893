import classNames from 'classnames';
import { Button } from 'src/components/buttons';

interface CoinBlockProps {
  className?: string;
  value: number;
}

const CoinBlock = ({ className, value, ...props }: CoinBlockProps) => {
  const coinBlockContainerClassName = classNames('coin-block-container', className);

  return (
    <div className={coinBlockContainerClassName} {...props}>
      <div className="header-container">
        <div className="left-content">
          <span className="token">Idle Glory Coin</span>
          <span className="currency-1">1.2 IGC</span>
          <span className="currency-2">0.12 USD</span>
        </div>
        <div className="right-content">
          <div className="token-icon" />
        </div>
      </div>
      <div className="footer-content">
        <Button color="primary" content="Exchange" compact />
      </div>
    </div>
  );
};

CoinBlock.displayName = 'CoinBlock';

export default CoinBlock;
