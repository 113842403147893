import { netId, isProdEnv } from './../../env';
import { currentNoBoxes } from './../season/seasonApi';
import { showModal } from 'src/store/modal/modalSlice';
import { MODAL_TYPE } from './../modal/modalSlice';
import { erc20Contract, web3 } from 'src/utils/contracts/contract';
import { ACCOUNT_API_URL, TOKEN, TOKEN_UNIT } from 'src/env';
import { client } from 'src/utils/api';
import { store } from '../store';
import { fetchBalance, login as loginDispatch } from 'src/store/auth/authSlice';

declare let window: any;
export interface NonceData {
  address: string;
  nonce: string;
}

export interface NonceData {
  address: string;
  nonce: string;
}

export interface TokenData {
  accessToken: string;
}

export const getNonceData = (params): Promise<NonceData> => client(ACCOUNT_API_URL).get(`/authentication/nonce`, { params });

export const login = (body): Promise<TokenData> => client(ACCOUNT_API_URL).post(`/authentication/token`, body);

// export const linkAccount = (body) => client.post(`/profile/link-account`, body);

const showModalConnectFailed = (title = 'Cannot connect Metamask', subtitle = '') => {
  store.dispatch(showModal({ type: MODAL_TYPE.FAILED, data: { closable: false, title, subtitle } }));
};

export const getBalance = async (address: string | null) => {
  const contract = erc20Contract(TOKEN.address);
  const weiBalance = await contract.methods.balanceOf(address).call();
  const balance = +web3.utils.fromWei(weiBalance, TOKEN_UNIT);

  fetchBalance(balance);

  return balance;
};

export const connect = async (callback?: () => void) => {
  const { ethereum } = window;

  try {
    // First request user to login to their wallet
    const [user] = await ethereum.request({ method: 'eth_requestAccounts' });

    // Check if user is connected to required Net
    const chainId = await ethereum.request({ method: 'eth_chainId' });

    if (!isProdEnv && chainId !== netId.test) {
      showModalConnectFailed('Please connect to BSC TestNet');
      return;
    }

    // Temporary test purpose
    if (isProdEnv && chainId !== netId.test) {
      showModalConnectFailed('Please connect to BSC TestNet');
      return;
    }

    // if (isProdEnv && chainId !== netId.main) {
    //   showModalConnectFailed('Please connect to BSC MainNet');
    //   return;
    // }

    // If user already loggedIn, process the callback function
    const { loggedIn } = store.getState().auth;

    if (loggedIn) {
      if (typeof callback === 'function') callback();
    }
    // Else get the user data
    else {
      try {
        const { address, nonce } = await getNonceData({ address: user });
        const message = `${nonce}`;
        const signature = await web3.eth.personal.sign(message, address, '');
        const { accessToken } = await login({ address, signature });

        const balance = await getBalance(address);
        const noBoxes = await currentNoBoxes(address);

        store.dispatch(loginDispatch({ address, accessToken, balance, noBoxes }));

        if (typeof callback === 'function') callback();
      } catch (err: any) {
        console.error(err);
      }
    }
  } catch (err: any) {
    if (err.code === 4001) {
      // EIP-1193 userRejectedRequest error
      // If this happens, the user rejected the connection request.
      showModalConnectFailed();
    } else {
      console.error(err);
    }
  }
};
