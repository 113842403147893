import { FC, ReactNode } from 'react';

import ModalBase from '../../components/ModalBase';
import imgBox from 'src/assets/boxes/box-idle-1.png';
import FormBuyBox from './components/FormBuyBox';
import Countdown from '../../../../displays/countdown/Countdown';

interface ModalBuyBoxProps {
  title?: string;
  subtitle?: string;
  content?: string;
  boxImgUrl?: string;
  closable?: boolean;
  children?: ReactNode;
}

const INFO_SUBTITLE = "What's in this box";
const INFO_CONTENT =
  'Vestibulum sodales lorem in erat vehicula commodo. Quisque dolor magna, venenatis eget risus non, auctor facilisis est. Nunc magna nisl, cursus sed posuere eget, facilisis aliquam tortor. Donec eu dapibus eros.';

export type FormValues = {
  amount: number;
};

const defaultProps = { title: 'Glory Box', subtitle: INFO_SUBTITLE, content: INFO_CONTENT, boxImgUrl: imgBox, amount: 500, closable: true };

const ModalBuyBox: FC<ModalBuyBoxProps> = ({ title, subtitle, content, boxImgUrl, closable }) => {
  return (
    <ModalBase
      size="large"
      closable={closable}
      title={
        <>
          {title}
          <Countdown />
        </>
      }
      subtitle={subtitle}
      itemUrl={boxImgUrl}
    >
      <p className="mb-12">{content}</p>
      <FormBuyBox />
    </ModalBase>
  );
};

ModalBuyBox.defaultProps = defaultProps;
ModalBuyBox.displayName = 'ModalBuyBox';

export default ModalBuyBox;
