import { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useAppSelector, useAppDispatch } from 'src/store/hook';
import { selectAuth } from 'src/store/auth/authSlice';
import { fetchBoxes } from 'src/store/box/boxSlice';

interface PaginationProps {
  size: number;
  index: number;
  className?: string;
}

const defaultProps = { size: 0, index: 1 };

const Pagination = ({ className, size, index }: PaginationProps) => {
  const [currentIndex, setCurrentIndex] = useState(index);
  const paginationWrapperClassName = classNames('pagination', className);

  const auth = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    validateInput(currentIndex);
  };

  const handleDecrease = () => {
    validateInput(currentIndex - 1);
  };

  const handleIncrease = () => {
    validateInput(currentIndex + 1);
  };

  const handleChangeNumber = (e) => {
    e.preventDefault();
    const value = Number.parseInt(e.target.value);
    setCurrentIndex(value);
  };

  const validateInput = (value) => {
    if (auth.address) {
      if (value >= 1 && value <= size) {
        setCurrentIndex(Number(value));
        dispatch(fetchBoxes({ owner: auth.address, page: value }));
      } else {
        setCurrentIndex(1);
        dispatch(fetchBoxes({ owner: auth.address, page: 1 }));
      }
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  return (
    <form onSubmit={handleSubmit} className={paginationWrapperClassName}>
      <button type="button" className="btn-previous" disabled={currentIndex <= 1} onClick={handleDecrease} />
      <input className="index-container" value={currentIndex} min={1} max={size} type="number" onChange={handleChangeNumber} />
      {`of ${size}`}
      <button type="button" className="btn-next" disabled={currentIndex >= size} onClick={handleIncrease} />
    </form>
  );
};

Pagination.displayName = 'Pagination';
Pagination.defaultProps = defaultProps;

export default Pagination;
