import { InfoBlock, ProgressBar } from 'src/components/displays';
import FormBuyItems from './FormBuyItems';

const ItemContentGear = ({ content }) => {
  return (
    <>
      <div className="modal-item-content">
        <InfoBlock title="Description" className="mb-8" content={content} />
        <InfoBlock title="Stats" className="mb-8">
          <ProgressBar type="info" value={180} maxValue={360} className="mb-7" />
          <ProgressBar type="error" value={6000} maxValue={7200} className="mb-7" />
          <ProgressBar type="success" value={1100} maxValue={1200} className="mb-7" />
        </InfoBlock>
      </div>
      <div className="modal-item-actions">
        <div className="flex-1 price-block">
          <h6>Price</h6>
          <span>0.361 IGL</span>
        </div>
        <div className="flex-1 relative z-10">
          <FormBuyItems />
        </div>
      </div>
    </>
  );
};

ItemContentGear.propTypes = {};

export default ItemContentGear;
