import { InfoBlock, ProgressBar } from 'src/components/displays';
import FormBuyItems from './FormBuyItems';

const ItemContentHero = ({ content }) => {
  return (
    <>
      <div className="modal-item-content">
        <InfoBlock title="Story" className="mb-8" content={content} />
        <InfoBlock title="Stats">
          <ProgressBar type="error" content="base health" value={360} maxValue={360} className="mb-7" />
          <ProgressBar type="success" content="base attack" value={180} maxValue={360} className="mb-7" />
        </InfoBlock>
        <InfoBlock title="Skill">
          <div className="flex flex-row gap-4">
            <div className="w-36 h-36 bg-gray-600" />
            <div className="w-36 h-36 bg-gray-600" />
            <div className="w-36 h-36 bg-gray-600" />
            <div className="w-36 h-36 bg-gray-600" />
          </div>
        </InfoBlock>
      </div>
      <div className="modal-item-actions">
        <div className="flex-1 price-block">
          <h6>Price</h6>
          <span>0.361 IGL</span>
        </div>
        <div className="flex-1 relative z-10">
          <FormBuyItems />
        </div>
      </div>
    </>
  );
};

export default ItemContentHero;
