import axios from 'axios';
import { stringify } from 'query-string';
import { MODAL_TYPE, showModal } from 'src/store/modal/modalSlice';
import { store } from 'src/store/store';
import { logout } from '../store/auth/authSlice';

const { REACT_APP_API_URL } = process.env;

export const showModalFailed = (content: string) => {
  store.dispatch(showModal({ type: MODAL_TYPE.FAILED, data: { subtitle: content } }));
};

const onError = ({ response }) => {
  if (response) {
    const { status, statusText } = response;

    if (status === 401) {
      logout();
    } else {
      showModalFailed(`${status} - ${statusText}`);
    }
  } else {
    showModalFailed('Cannot connect to Server');
  }
  return Promise.reject(response);
};

const beforeRequest = (_config, baseURL) => {
  const config = { ..._config };
  const { accessToken } = store.getState().auth;

  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  config.url = (config.url.includes(baseURL) ? '' : baseURL) + config.url;
  config.headers['Content-Type'] = 'application/json';

  return config;
};

export const client = (baseURL = REACT_APP_API_URL) => {
  const instance = axios.create({
    baseURL,
    paramsSerializer: (params) => stringify(params, { arrayFormat: 'index' }),
  });

  instance.interceptors.request.use(
    (config) => beforeRequest(config, baseURL),
    (err) => Promise.reject(err),
  );

  [instance].forEach((item) => {
    item.interceptors.response.use(({ data: response }) => {
      const { success = true, data, errors } = response;
      if (success) return data;
      return Promise.reject(errors);
    }, onError);
  });

  return instance;
};
