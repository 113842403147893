import classNames from 'classnames';
import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';

export interface TableBodyProps extends DetailedHTMLProps<HTMLAttributes<HTMLTableSectionElement>, HTMLTableSectionElement> {
  className?: string;
}

const TableBody: FC<TableBodyProps> = ({ className, children, ...props }) => {
  const tbodyClassName = classNames('table-body', className);

  return (
    <tbody className={tbodyClassName} {...props}>
      {children}
    </tbody>
  );
};

export default TableBody;
