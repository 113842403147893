import { RootState } from '../store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum MODAL_TYPE {
  BUY_BOX = 'BUY_BOX',
  LOGIN = 'LOGIN',
  CHECKOUT = 'CHECKOUT',
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  TERMS_OF_USE = 'TERMS_OF_USE',
  SET_PRICE = 'SET_PRICE',
}

export type ModalState = {
  visible: boolean;
} & ModalOpenData;

export interface ModalOpenData {
  type: MODAL_TYPE;
  data?: Record<string, unknown> | null;
}

export const initialState: ModalState = {
  visible: false,
  type: MODAL_TYPE.CHECKOUT,
  data: null,
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    showModal: (state, { payload: { type, data } }: PayloadAction<ModalOpenData>) => {
      if (!state.visible) {
        state.visible = true;
      }
      state.type = type;
      state.data = data;
      document.body.classList.toggle('modal-opened');
    },
    hideModal: () => {
      document.body.classList.toggle('modal-opened');
      return initialState;
    },
  },
});

export const { showModal, hideModal } = modalSlice.actions;
export const selectModal = (state: RootState) => state.modal;
export default modalSlice.reducer;
