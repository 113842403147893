import { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import classNames from 'classnames';
import _ from 'lodash';

interface ItemAccordionProps {
  content: string;
  isActive: boolean;
}

interface FilterAccordionProps {
  content: string;
  items: ItemAccordionProps[];
  className?: string;
  name: string;
  callback?: any;
}

const FilterAccordion = ({ content, items, className, name, callback }: FilterAccordionProps) => {
  const method = useFormContext();
  const [isExpand, setIsExpand] = useState(false);
  const [itemState, setItemState] = useState(items);
  const { onChange, ...rest } = method.register(name);
  const filterAccordionClassname = classNames('filter-accordion', className);
  const iconClassname = classNames('icon', { 'icon-plus': !isExpand }, { 'icon-minus': isExpand });
  const contentContainerClassname = classNames('content-container', { expand: isExpand });

  const watchValue = useWatch({ control: method.control, name });

  const handleExpand = () => {
    setIsExpand(!isExpand);
  };

  const handleItemClick = (e) => {
    const newItems = _.xor(method.getValues(name), [e.target.name]);
    method.setValue(name, newItems);
    onChange(e);

    if (callback) {
      callback();
    }
  };

  const renderItemList = () => {
    return itemState?.map((item, index) => {
      return (
        <div key={index}>
          <div className="item-container">
            <input {...rest} type="checkbox" id={item.content} name={item.content} checked={watchValue?.includes(item.content)} onChange={handleItemClick} />
            <label htmlFor={item.content}>
              <div className="icon" />
              {item.content}
            </label>
          </div>
          <hr />
        </div>
      );
    });
  };

  return (
    <div className={filterAccordionClassname}>
      <div className="title-container" onClick={handleExpand}>
        <p>{content}</p>
        <div className={iconClassname}></div>
      </div>
      <div className={contentContainerClassname}>{renderItemList()}</div>
    </div>
  );
};

FilterAccordion.displayName = 'FilterAccordion';

export default FilterAccordion;
