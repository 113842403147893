import { useCallback } from 'react';
import classNames from 'classnames';
import useClickOutside from 'src/hooks/useClickOutside';

interface SelectSellProps {
  className?: string;
}

const SelectSell = ({ className }: SelectSellProps) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useClickOutside(false);
  const filterSelectClassName = classNames('sell-select', className);
  const contentContainerClassname = classNames('content-container', { expand: isComponentVisible });

  const handleChangeStatus = useCallback(
    (e) => {
      if (e) {
        e.preventDefault();
      }
      setIsComponentVisible(!isComponentVisible);
    },
    [isComponentVisible],
  );

  return (
    <div className={filterSelectClassName} ref={ref}>
      <div className="title-container">
        <span className="title-left">Sort by:</span>
        <button className="title-right" type="button" id="menu-button" aria-expanded="true" aria-haspopup="true" onClick={handleChangeStatus}>
          Ready to sale
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
      {isComponentVisible && (
        <div className={contentContainerClassname} role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex={-1}>
          <a href="#" role="menuitem" tabIndex={-1} id="menu-item-0">
            Lowest ID
          </a>
          <a href="#" role="menuitem" tabIndex={-1} id="menu-item-1">
            Highed ID
          </a>
          <a href="#" role="menuitem" tabIndex={-1} id="menu-item-2">
            Lowest Price
          </a>
          <a href="#" role="menuitem" tabIndex={-1} id="menu-item-2">
            Highest Price
          </a>
          <a href="#" role="menuitem" tabIndex={-1} id="menu-item-2">
            Recently Listed
          </a>
        </div>
      )}
    </div>
  );
};

SelectSell.displayName = 'SelectSell';

export default SelectSell;
