import { ReactNode } from 'react';

export interface ModalContentProps {
  subtitle?: string;
  children?: ReactNode;
}

const ModalContent = ({ subtitle, children }: ModalContentProps) => {
  let subtitleContent = <h2 className="modal-subtitle">{subtitle}</h2>;

  if (subtitle?.startsWith('http')) {
    subtitleContent = (
      <a className="modal-subtitle" href={subtitle} target="_blank" rel="noreferrer">
        {subtitle}
      </a>
    );
  }

  return (
    <div className="modal-content">
      {subtitleContent}
      {children}
    </div>
  );
};

export default ModalContent;
