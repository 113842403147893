import { FC } from 'react';
import _ from 'lodash';
import { List } from 'src/components/displays';

import unitUrl from 'src/assets/units/avatars/mel-s-1.png';
import rankAUrl from 'src/assets/badges/rank-a.svg';
import hackerUrl from 'src/assets/badges/hacker.svg';

const { Container, Item, ItemAvatar, ItemText, ItemIcon } = List;

const data = _.times(10, () => ({
  id: '#29484989',
  imgUrl: unitUrl,
  level: 132,
  badges: [
    {
      id: 0,
      title: 'Rank A',
      imgUrl: rankAUrl,
    },
    {
      id: 1,
      title: 'Hacker',
      imgUrl: hackerUrl,
    },
  ],
  seller: { address: 'Ox294...989' },
  buyer: { address: 'Ox294...989' },
  price: {
    base: 0.0123,
    toUSD: 456.78,
    lastUpdated: 'a few second ago',
  },
}));

export const ListDemo: FC = () => {
  return (
    <Container title="Recently listed">
      <List>
        {_.map(data, (item, index) => (
          <Item key={index}>
            <ItemAvatar>
              <img src={item.imgUrl} alt={item.id} />
            </ItemAvatar>
            <ItemText primary="ID" secondary={item.id} />
            <ItemText primary="Level" secondary={item.level} />
            <div className="flex items-center">
              {_.map(item.badges, (badge) => (
                <ItemIcon key={badge.id}>
                  <img src={badge.imgUrl} alt={badge.imgUrl} />
                </ItemIcon>
              ))}
            </div>
            <ItemText
              className="currency flex-1"
              primary={item.price.base}
              secondary={
                <div className="flex flex-col">
                  <span>{item.price.toUSD}</span>
                  <span className="caption">{item.price.lastUpdated}</span>
                </div>
              }
            />
          </Item>
        ))}
      </List>
    </Container>
  );
};

export const ListDemo2: FC = () => {
  return (
    <Container title="Recently listed">
      <List>
        {_.map(data, (item, index) => (
          <Item key={index}>
            <ItemAvatar>
              <img src={item.imgUrl} alt={item.id} />
            </ItemAvatar>
            <ItemText primary="ID" secondary={item.id} />
            <ItemText primary="Buyer" secondary={item.buyer.address} />
            <ItemText primary="Seller" secondary={item.seller.address} />
            <ItemText
              className="currency flex-1"
              primary={item.price.base}
              secondary={
                <div className="flex flex-col">
                  <span>{item.price.toUSD}</span>
                  <span className="caption">{item.price.lastUpdated}</span>
                </div>
              }
            />
          </Item>
        ))}
      </List>
    </Container>
  );
};
