import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import logo from 'src/assets/logos/idle-glory.png';
import ButtonHeader from 'src/components/buttons/components/header/ButtonHeader';
import LoginButton from 'src/components/buttons/LoginButton';
import { HeaderInfo } from 'src/components/displays';
import { isDevEnv } from 'src/env';
import { selectAuth } from 'src/store/auth/authSlice';
import { useAppSelector } from 'src/store/hook';

interface HeaderProps {
  disabled?: boolean;
  className?: string;
}

const defaultProps = { disabled: false, className: '' };

const Header = ({ disabled, className }: HeaderProps) => {
  const { loggedIn, address, balance } = useAppSelector(selectAuth);
  const navigate = useNavigate();

  const goToHome = () => {
    navigate('/');
  };

  return (
    <div className={`header ${className}`}>
      <img alt="Idle Glory" src={logo} onClick={goToHome} />
      <div className="header-btn-container">
        <NavLink to="/">
          <ButtonHeader content="Dashboard" />
        </NavLink>
        <NavLink to="/marketplace">
          <ButtonHeader disabled={disabled} content="Marketplace" />
        </NavLink>
        <NavLink to="/buy">
          <ButtonHeader disabled={disabled} content="Buy Box" />
        </NavLink>
        <NavLink to="/inventory">
          <ButtonHeader disabled={disabled} content="Inventory" />
        </NavLink>
        {isDevEnv && (
          <NavLink to="/demo">
            <ButtonHeader disabled={disabled} content="Demo" />
          </NavLink>
        )}
      </div>
      {!disabled &&
        (loggedIn && address ? <HeaderInfo balance={balance} userId={address} className="ml-auto mr-4" /> : <LoginButton className="header-btn-login" />)}
    </div>
  );
};

Header.defaultProps = defaultProps;

export default Header;
