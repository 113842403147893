import { FC } from 'react';
import { useMenu } from '../MenuContext';
import { menuItemComponents } from './menuItemComponents';

export interface MenuItemProps {
  name: string;
  content?: string;
  className?: string;
  disabled?: boolean;
}

// Render object based on type
const MenuItem: FC<MenuItemProps> = ({ name, content, className, children, disabled }) => {
  const { type, activeItem, onClick } = useMenu();

  const active = activeItem === name;

  const handleOnClick = (e: any) => {
    onClick(e, name);
  };

  return <>{menuItemComponents({ active, content, onClick: handleOnClick, className, children, disabled })[type]}</>;
};

export default MenuItem;
