import classNames from 'classnames';
import Button, { ButtonProps } from 'src/components/buttons/Button';

interface ButtonHeaderProps {
  active?: boolean;
}

const ButtonHeader = ({ active, className, ...props }: ButtonHeaderProps & ButtonProps) => {
  const btnHeaderClassName = classNames('btn-header', { ['active']: active }, className);

  return <Button className={btnHeaderClassName} {...props} />;
};

ButtonHeader.displayName = 'ButtonHeader';

export default ButtonHeader;
