import classNames from 'classnames';
import Button, { ButtonProps } from 'src/components/buttons/Button';

interface OutlinedButtonProps {
  className?: string;
  icon?: string;
}

const OutlinedButton = ({ className, icon, ...props }: OutlinedButtonProps & ButtonProps) => {
  const buttonClassName = classNames('btn-outlined', className);

  return <Button icon={icon} className={buttonClassName} {...props} />;
};

OutlinedButton.displayName = 'OutlinedButton';

export default OutlinedButton;
