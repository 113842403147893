const IconSort = ({ color = '#705512' }) => {
  return (
    <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.894314 10.5608C1.1747 10.2804 1.61588 10.2588 1.921 10.4961L1.99428 10.5608L5.33325 13.8998L8.67223 10.5608C8.97597 10.257 9.46844 10.257 9.77219 10.5608C10.0526 10.8412 10.0741 11.2823 9.83689 11.5875L9.77219 11.6607L5.88323 15.5497C5.60285 15.8301 5.16166 15.8516 4.85654 15.6144L4.78327 15.5497L0.894314 11.6607C0.590567 11.357 0.590567 10.8645 0.894314 10.5608ZM5.88323 0.44949L9.77219 4.33845C10.0759 4.64219 10.0759 5.13466 9.77219 5.43841C9.46844 5.74216 8.97597 5.74216 8.67223 5.43841L5.33325 2.09943L1.99428 5.43841C1.69053 5.74216 1.19806 5.74216 0.894314 5.43841C0.590567 5.13466 0.590567 4.64219 0.894314 4.33845L4.78327 0.44949C5.08702 0.145743 5.57949 0.145743 5.88323 0.44949Z"
        fill={color}
      />
    </svg>
  );
};

export default IconSort;
