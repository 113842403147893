import Pagination from 'src/components/displays/pagination/Pagination';

import EmptyTransaction from 'src/components/displays/empty/EmptyTransaction';
import { selectAuth } from 'src/store/auth/authSlice';
import { useAppSelector, useAppDispatch } from 'src/store/hook';
import { fetchBoxes } from 'src/store/box/boxSlice';
import { selectBox } from 'src/store/box/boxSlice';
import _ from 'lodash';
import { useEffect } from 'react';
import Boxes from 'src/pages/inventory/components/Boxes';

export enum InventoryTab {
  Boxes = 'boxes',
  Heroes = 'heroes',
  Assets = 'assets',
}

const TabBoxes = ({ loadingSync }) => {
  const auth = useAppSelector(selectAuth);
  const boxes = useAppSelector(selectBox);
  const dispatch = useAppDispatch();

  const handleGetBoxes = (address: string) => {
    dispatch(fetchBoxes({ owner: address }));
  };

  useEffect(() => {
    if (auth.address) {
      handleGetBoxes(auth.address);
    }
  }, [auth.address, loadingSync]);

  if (_.isEmpty(boxes.items)) {
    return <EmptyTransaction content="Empty Transaction History" />;
  }

  return (
    <>
      <Boxes boxes={boxes.items} />
      <Pagination size={boxes.pages} className="mt-10" />
    </>
  );
};

export default TabBoxes;
