import _ from 'lodash';
import { Container } from 'src/components/layouts';

import unitUrl from 'src/assets/units/avatars/mel-s-1.png';
import rankAUrl from 'src/assets/badges/rank-a.svg';
import hackerUrl from 'src/assets/badges/hacker.svg';
import { RecentListBuyerSeller, RecentListLevelBadges } from './components/RecentList';
import Score from 'src/components/displays/score/Score';

const data1 = _.times(10, () => ({
  id: '#29484989',
  imgUrl: unitUrl,
  level: 132,
  badges: [
    {
      id: 0,
      title: 'Rank A',
      imgUrl: rankAUrl,
    },
    {
      id: 1,
      title: 'Hacker',
      imgUrl: hackerUrl,
    },
  ],
  price: {
    base: 0.0123,
    toUSD: 456.78,
    lastUpdated: 'a few second ago',
  },
}));

const data2 = _.times(10, () => ({
  id: '#29484989',
  imgUrl: unitUrl,
  seller: { address: 'Ox294...989' },
  buyer: { address: 'Ox294...989' },
  price: {
    base: 0.0123,
    toUSD: 456.78,
    lastUpdated: 'a few second ago',
  },
}));

const Dashboard = () => {
  return (
    <Container className="w-11/12 lg:w-4/6">
      <div className="w-full flex items-start justify-between gap-5 mb-20">
        <Score text="Total users" number={290000} />
        <Score text="Total users" number={290000} />
        <Score text="Total users" number={290000} />
      </div>
      <div className="w-full flex items-start justify-between gap-20">
        <RecentListLevelBadges items={data1} />
        <RecentListBuyerSeller items={data2} />
      </div>
    </Container>
  );
};

export default Dashboard;
