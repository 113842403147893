import { ReactNode } from 'react';

import MenuItem from './menu-item/MenuItem';
import MenuContext, { MenuContextProps } from './MenuContext';

export type MenuDirection = 'vertical' | 'horizontal';

interface MenuProps {
  direction: MenuDirection;
  className: string;
  children: ReactNode;
}

const defaultProps = { direction: 'vertical', type: 'default', className: '' };

const Menu = ({ activeItem, onClick, direction, type, className, children }: MenuProps & MenuContextProps) => {
  const baseClassName = `menu ${direction}`;
  const finalClassName = className ? `${baseClassName} ${className}` : baseClassName;

  return (
    <MenuContext.Provider value={{ type, activeItem, onClick }}>
      <ul className={finalClassName}>{children}</ul>
    </MenuContext.Provider>
  );
};

Menu.defaultProps = defaultProps;
Menu.Item = MenuItem;

export default Menu;
