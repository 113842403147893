const BannerNoHistory = () => (
  <svg width="168" height="138" viewBox="0 0 168 138" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.8">
      <path
        d="M82.1983 119.112C113.413 119.112 138.72 93.806 138.72 62.4795C138.72 31.1529 113.302 5.84644 82.1983 5.84644C50.9833 5.84644 25.6768 31.1529 25.6768 62.4795C25.6768 93.806 50.9833 119.112 82.1983 119.112Z"
        fill="#FFE9AF"
      />
      <path
        d="M157.551 26.7321C160.076 26.7321 162.122 24.6857 162.122 22.1614C162.122 19.637 160.076 17.5906 157.551 17.5906C155.027 17.5906 152.98 19.637 152.98 22.1614C152.98 24.6857 155.027 26.7321 157.551 26.7321Z"
        fill="#FFBA1A"
      />
      <path
        d="M164.241 8.89511C165.965 8.89511 167.362 7.49756 167.362 5.7736C167.362 4.04965 165.965 2.6521 164.241 2.6521C162.517 2.6521 161.119 4.04965 161.119 5.7736C161.119 7.49756 162.517 8.89511 164.241 8.89511Z"
        fill="#FFBA1A"
      />
      <path
        d="M28.6869 25.2445C30.4109 25.2445 31.8084 23.8469 31.8084 22.123C31.8084 20.399 30.4109 19.0015 28.6869 19.0015C26.963 19.0015 25.5654 20.399 25.5654 22.123C25.5654 23.8469 26.963 25.2445 28.6869 25.2445Z"
        fill="#FFBA1A"
      />
      <path
        d="M92.0544 87.9223L90.0796 87.895C89.6846 87.8895 89.295 87.489 89.3248 87.0014C89.3547 86.5137 89.6623 86.0996 90.0573 86.1051L92.0321 86.1324C92.4271 86.1378 92.8167 86.5383 92.7869 87.026C92.7326 87.6063 92.4494 87.9277 92.0544 87.9223Z"
        fill="#CED7E2"
      />
      <path
        d="M83.2215 37.4176L107.172 86.5947C107.972 88.2985 107.265 90.2795 105.562 91.0799L57.2533 113.894C55.5497 114.695 53.5687 113.987 52.7682 112.284L22.7464 48.6356C21.9459 46.9318 22.653 44.9508 24.3566 44.1504L59.3189 27.687L83.2215 37.4176Z"
        fill="#FFBA1A"
        stroke="#705512"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path d="M60.252 26.1636L66.6583 39.7985C67.588 41.674 69.9805 42.5013 71.8548 41.5723L84.1541 35.902" fill="#D6DCE8" />
      <path
        d="M117.736 38.9994L118.449 93.6811C118.448 95.563 116.963 97.0525 115.08 97.0493L61.6394 97.0657C59.7566 97.0625 58.2654 95.5777 58.2672 93.6957L58.2269 23.34C58.2287 21.458 59.713 19.9686 61.5958 19.9718L100.252 20.0034L117.736 38.9994Z"
        fill="#FFBA1A"
        stroke="#705512"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path d="M100.262 19.991L100.245 35.0482C100.286 37.1401 102.098 38.9079 104.19 38.8671L117.738 38.9846" fill="#D58900" />
      <path
        d="M149.832 64.0636L127.504 114.028C126.761 115.714 124.682 116.451 123.02 115.615L74.5889 93.2765C72.9017 92.5324 72.1621 90.4541 72.905 88.7684L102.367 24.9403C103.11 23.2546 105.189 22.5172 106.852 23.3539L142.019 39.6034L149.832 64.0636Z"
        fill="#FFBA1A"
        stroke="#705512"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path d="M141.926 39.5607L135.629 53.252C134.72 55.1912 135.688 57.5284 137.629 58.4384L149.827 64.0461" fill="#D58900" />
    </g>
  </svg>
);

interface TableEmptyProps {
  title?: string;
}

const TableEmpty = ({ title = 'No history found' }: TableEmptyProps): JSX.Element => {
  return (
    <div className="h-full flex flex-col justify-center items-center font-bold text-info-header">
      <BannerNoHistory />
      <h6>{title}</h6>
    </div>
  );
};

export default TableEmpty;
