import { useEffect, useState } from 'react';
import { selectAuth } from 'src/store/auth/authSlice';
import { isEnoughAllowance } from 'src/store/season/seasonApi';
import BuyButton from 'src/components/buttons/BuyButton';
import { useFormContext } from 'react-hook-form';
import { Button } from 'src/components/buttons';
import { useAppSelector } from 'src/store/hook';
import useApproveAllowance from 'src/store/season/useAllowanceApprove';

interface FormBuyBoxButtonrops {
  name: string;
  price: number;
  min: number;
  max: number;
}

const FormBuyBoxButton = ({ name, price, min, max }: FormBuyBoxButtonrops) => {
  const { watch } = useFormContext();
  const { address } = useAppSelector(selectAuth);
  const [buyAvailable, setBuyAvailable] = useState(false);

  const [approveMax, loading] = useApproveAllowance(address);

  useEffect(() => {
    const handleSetBuyAvailable = async () => {
      setBuyAvailable(await isEnoughAllowance(watch(name), address));
    };

    if (!loading) {
      handleSetBuyAvailable();
    }
  }, [watch(name), address, loading]);

  const handleApprove = () => {
    // approve(price * watch(name));
    approveMax();
  };

  const valueInvalid = watch(name) < min || watch(name) > max || !watch(name);

  if (valueInvalid) {
    return <BuyButton disabled amount={0} type="button" />;
  }

  if (loading) {
    return <Button content="Approving..." disabled color="primary" type="button" />;
  }

  if (!address || (address && buyAvailable)) {
    return <BuyButton disabled={valueInvalid} amount={price * watch(name)} type="submit" />;
  }

  return <Button content="Approve" disabled={valueInvalid} color="primary" type="button" onClick={() => handleApprove()} />;
};

export default FormBuyBoxButton;
