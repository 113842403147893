import { StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import { persistor, store } from './store/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

ReactDOM.render(
  <Suspense fallback={<>Loading...</>}>
    <StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </StrictMode>
  </Suspense>,
  document.getElementById('root'),
);
