import classNames from 'classnames';
import { useFormContext, useWatch } from 'react-hook-form';
import { useState, useCallback } from 'react';

interface SwitchProps {
  className?: string;
  active: boolean;
  name: string;
  callback?: () => void;
}

const Switch = ({ className, active, name, callback }: SwitchProps) => {
  const method = useFormContext();
  const { onChange, ...rest } = method.register(name);
  const watchValue = useWatch({ control: method.control, name }) === 'true';
  const switchClassName = classNames('btn-switch', className, { active: status });

  const handleChangeStatus = (e) => {
    onChange(e);
    method.setValue(name, e.target.checked);
    if (callback) {
      callback();
    }
  };

  return (
    <label className={switchClassName}>
      <input {...rest} type="checkbox" defaultChecked={watchValue} onChange={handleChangeStatus} />
      <span className="slider round"></span>
    </label>
  );
};

Switch.displayName = 'Switch';

export default Switch;
