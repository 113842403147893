import { RootState } from '../store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface RequestLogin {
  accessToken: string | null;
  address: string | null;
  balance: number;
  noBoxes: number;
}

export type AuthState = {
  loggedIn: boolean;
} & RequestLogin;

export const initialState: AuthState = {
  loggedIn: false,
  accessToken: null,
  address: null,
  balance: 0,
  noBoxes: 0,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, { payload: { accessToken, address, balance, noBoxes } }: PayloadAction<RequestLogin>) => {
      state.loggedIn = true;
      state.accessToken = accessToken;
      state.address = address;
      state.balance = +balance;
      state.noBoxes = +noBoxes;
    },
    logout: () => initialState,
    fetchToken: (state, { payload }: PayloadAction<string>) => {
      state.accessToken = payload;
    },
    fetchBalance: (state, { payload }: PayloadAction<number>) => {
      state.balance = payload;
    },
    fetchAuth: (state, { payload }: PayloadAction<AuthState>) => ({
      ...state,
      ...payload,
      loggedIn: true,
    }),
  },
});

export const { login, logout, fetchToken, fetchAuth, fetchBalance } = authSlice.actions;
export const selectAuth = (state: RootState) => state.auth;
export default authSlice.reducer;
