import classNames from 'classnames';
import { DetailedHTMLProps, FC, ReactNode, TdHTMLAttributes } from 'react';

export interface TableCellProps extends DetailedHTMLProps<TdHTMLAttributes<HTMLTableDataCellElement>, HTMLTableDataCellElement> {
  isCopyable?: boolean;
  className?: string;
  children?: ReactNode;
}

const TableCell: FC<TableCellProps> = ({ isCopyable = false, className, children, ...props }) => {
  const tdClassName = classNames('table-cell', className);

  return (
    <td className={tdClassName} {...props}>
      {children}
      {isCopyable && <span className="icon-copy" />}
    </td>
  );
};

TableCell.displayName = 'TableCell';

export default TableCell;
