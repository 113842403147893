import { RootState } from '../store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SeasonState {
  seasonCode: number;
  total: number;
  remaining: number;
  price: number;
  limitPerTransaction: number;
  limitPerUser: number;
  paymentToken: string | null;
  startDate: number;
  endDate: number;
  boxType: number;
}

export const initialState: SeasonState = {
  seasonCode: 0,
  total: 0,
  remaining: 0,
  price: 0,
  limitPerTransaction: 0,
  limitPerUser: 0,
  paymentToken: null,
  startDate: 0,
  endDate: 0,
  boxType: 0,
};

export const seasonSlice = createSlice({
  name: 'season',
  initialState,
  reducers: {
    fetchSeason: (state, action: PayloadAction<SeasonState>) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const { fetchSeason } = seasonSlice.actions;
export const selectSeason = (state: RootState) => state.season;
export default seasonSlice.reducer;
