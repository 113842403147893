import { MODAL_TYPE } from './../modal/modalSlice';
import { SEASON, TOKEN, TOKEN_UNIT } from 'src/env';
import { seasonContract, erc20Contract, web3 } from 'src/utils/contracts/contract';
import { store } from '../store';
import { fetchSeason } from './seasonSlice';
import { showModal } from '../modal/modalSlice';

export const getSeasonInfos = async () => {
  if (store.getState().season.seasonCode === 0) {
    const ssContract = seasonContract(SEASON.address);
    const ssInfos = await ssContract.methods.seasonInfos(SEASON.code).call();

    const seasonCode = ssInfos.seasonCode;
    const total = +ssInfos.total;
    const remaining = +ssInfos.remaining;
    const price = +web3.utils.fromWei(ssInfos.price, TOKEN_UNIT);
    const limitPerTransaction = +ssInfos.limitPerTransaction;
    const limitPerUser = +ssInfos.limitPerUser;
    const paymentToken = ssInfos.paymentToken;
    const startDate = +ssInfos.startDate;
    const endDate = +ssInfos.endDate;
    const boxType = +ssInfos.boxType;

    store.dispatch(fetchSeason({ seasonCode, total, remaining, price, limitPerTransaction, limitPerUser, paymentToken, startDate, endDate, boxType }));
  }
};

export const showModalFailed = (content: string) => {
  store.dispatch(showModal({ type: MODAL_TYPE.FAILED, data: { subtitle: content } }));
};

export const currentNoBoxes = async (userAddress: string | null) => {
  // Get the current boxes of user in the season
  const ssContract = seasonContract(SEASON.address);
  const seasonLimit = +(await ssContract.methods.seasonLimit(SEASON.code, userAddress).call());
  return seasonLimit;
};

/**
 * Check if the sum of number of current boxes of user and the amount that user want to buy don't exceed the season limit
 */
export const notExceedMaxBox = async (userAddress: string | null, amountToBuy: number): Promise<boolean> => {
  if (!userAddress) {
    showModalFailed('User undefined');
    return false;
  }

  const { limitPerUser } = store.getState().season;

  // Get the current boxes of user in the season
  const noBoxes = await currentNoBoxes(userAddress);

  if (noBoxes + amountToBuy <= limitPerUser) {
    return true;
  } else {
    showModalFailed('Exceed limit boxes by user.');
    return false;
  }
};

/**
 * Check if the user allowance is enough to make a purchase
 */
export const isEnoughAllowance = async (amount: number, userAddress: string | null): Promise<boolean> => {
  if (!userAddress) {
    return false;
  }

  const { price } = store.getState().season;

  const allowance = await erc20Contract(TOKEN.address).methods.allowance(userAddress, SEASON.address).call();
  const totalAmount = amount * price;

  return +web3.utils.fromWei(allowance, TOKEN_UNIT) >= totalAmount;
};
