import imgBannerLogin from 'src/assets/banners/banner-login.png';
import { connect } from 'src/store/auth/authApi';
import { useAppDispatch, useAppSelector } from 'src/store/hook';
import { hideModal, selectModal } from 'src/store/modal/modalSlice';
import ModalBase from '../../components/ModalBase';

const ModalLogin = () => {
  const dispatch = useAppDispatch();
  const { data } = useAppSelector(selectModal);

  const closable = data ? data.closable === undefined : true;

  const onCloseModal = () => {
    dispatch(hideModal());
  };

  const handleConnect = () => {
    connect(onCloseModal);
  };

  return (
    <ModalBase
      closable={closable}
      title="Login with Meta Mask"
      subtitle="Connect with your available wallet or create new wallet to continue"
      bannerUrl={imgBannerLogin}
      btns={
        closable
          ? [
              { content: 'Connect Now', color: 'primary', onClick: handleConnect },
              { content: 'Later', color: 'secondary', onClick: onCloseModal },
            ]
          : [{ content: 'Connect Now', color: 'primary', onClick: handleConnect }]
      }
    />
  );
};

ModalLogin.displayName = 'ModalLogin';

export default ModalLogin;
