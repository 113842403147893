import classNames from 'classnames';
import { ButtonHTMLAttributes, DetailedHTMLProps, ReactNode } from 'react';

export type ButtonColor = 'primary' | 'secondary';

export interface ButtonProps extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  content?: string;
  children?: ReactNode;
  color?: ButtonColor;
  className?: string;
  icon?: string;
  loading?: boolean;
  compact?: boolean;
}

const defaultProps = { color: 'secondary', loading: false };

const Button = ({ loading, content, color, children, className, icon, compact, disabled, ...props }: ButtonProps) => {
  const iconName = icon ? `icon icon-${icon}` : '';

  const btnClassName = classNames('btn btn-base', { [`btn-${color}`]: true, [`btn-flex`]: loading, 'btn-compact': compact }, className);

  return (
    <button className={btnClassName} disabled={disabled} {...props}>
      {icon && <i className={iconName} />}
      {children ? children : content}
      {loading && <div className="btn-loader" />}
    </button>
  );
};

Button.displayName = 'Button';
Button.defaultProps = defaultProps;

export default Button;
