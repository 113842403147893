import axios from 'axios';

export const fetcher = (url: string) => axios.get(url).then((res) => res);

export const shorten = (address: string, fromEnd = 6, fromStart = 2): string => {
  const n = address.length;
  return address.substr(0, fromStart) + '...' + address.substr(n - fromEnd);
};

type CallbackFunction = () => void;

export const noop = () => {};

export function on<T extends Window | Document | HTMLElement | EventTarget>(
  obj: T | null,
  ...args: Parameters<T['addEventListener']> | [string, CallbackFunction | null, ...any]
): void {
  if (obj && obj.addEventListener) {
    obj.addEventListener(...(args as Parameters<HTMLElement['addEventListener']>));
  }
}

export function off<T extends Window | Document | HTMLElement | EventTarget>(
  obj: T | null,
  ...args: Parameters<T['removeEventListener']> | [string, CallbackFunction | null, ...any]
): void {
  if (obj && obj.removeEventListener) {
    obj.removeEventListener(...(args as Parameters<HTMLElement['removeEventListener']>));
  }
}

export const isBrowser = typeof window !== 'undefined';

export const isNavigator = typeof navigator !== 'undefined';

export const formatMoney = (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const encodeQueryData = (data) => {
  const ret: string[] = [];
  for (const item in data) {
    if (data[item] === undefined) continue;
    if (Array.isArray(data[item])) {
      data[item].map((arrayItem) => {
        if (arrayItem) {
          ret.push(encodeURIComponent(item) + '=' + encodeURIComponent(arrayItem));
        }
      });
      continue;
    }
    ret.push(encodeURIComponent(item) + '=' + encodeURIComponent(data[item]));
  }
  return ret.join('&');
};

export const groupParamsByKey = (params) =>
  [...params.entries()].reduce((acc, tuple) => {
    // getting the key and value from each tuple
    const [key, val] = tuple;
    if (acc.hasOwnProperty(key)) {
      // if the current key is already an array, we'll add the value to it
      if (Array.isArray(acc[key])) {
        acc[key] = [...acc[key], val];
      } else {
        // if it's not an array, but contains a value, we'll convert it into an array
        // and add the current value to it
        acc[key] = [acc[key], val];
      }
    } else {
      // plain assignment if no special case is present
      acc[key] = val;
    }

    return acc;
  }, {});
