export const BOX_API_URL = process.env.REACT_APP_BOX_API_URL;
export const ACCOUNT_API_URL = process.env.REACT_APP_ACCOUNT_API_URL;
export const SYSTEM_API_URL = process.env.REACT_APP_SYSTEM_API_URL;
export const MARKETPLACE_API_URL = process.env.REACT_APP_MARKETPLACE_API_URL;

export const TOKEN = { name: 'IGL', address: process.env.REACT_APP_TOKEN_ADRESS };
export const TOKEN_2 = { name: 'BUSD', address: process.env.REACT_APP_TOKEN_2_ADRESS };
export const TOKEN_UNIT = 'ether';

export const SEASON = { address: process.env.REACT_APP_SEASON_ADDRESS, code: process.env.REACT_APP_SEASON_CODE || 400 };
export const MARKETPLACE = { address: process.env.REACT_APP_MARKETPLACE_ADDRESS };

export const isDevEnv = process.env.NODE_ENV === 'development';
export const isTestEnv = process.env.NODE_ENV === 'test';
export const isProdEnv = process.env.NODE_ENV === 'production';

export const netId = { main: process.env.REACT_APP_MAINNET_ID, test: process.env.REACT_APP_TESTNET_ID };
