import classNames from 'classnames';
import { FC, ReactNode } from 'react';

export interface TableContainerProps {
  title?: string | ReactNode;
  className?: string;
}

const TableContainer: FC<TableContainerProps> = ({ title, className, children }) => {
  const tableContainerClassName = classNames('table-container', className);

  const renderTitle = typeof title === 'string' ? <h6>{title}</h6> : title;

  return (
    <div className={tableContainerClassName}>
      {title && renderTitle}
      {children}
    </div>
  );
};

export default TableContainer;
