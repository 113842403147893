import { createSlice, PayloadAction, SerializedError, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { BoxesModel } from './box.model';
import boxApi from './boxApi';

export type BoxState = {
  loading: 'idle' | 'pending';
  error: SerializedError | null;
} & BoxesModel;

export interface BoxRequest {
  owner: string;
  sortBy?: string;
  page?: number;
}

export const fetchBoxes = createAsyncThunk('boxes/fetch', async ({ owner, page, sortBy }: BoxRequest) => {
  const res = await boxApi.fetchBoxes({ owner, page, sortBy });
  return res;
});

export const syncBoxes = createAsyncThunk('boxes/sync', async () => {
  const res = await boxApi.syncBoxData();
  return res;
});

export const initialState: BoxState = {
  items: [],
  loading: 'idle',
  error: null,
  hasNext: false,
  hasPrevious: false,
  pages: 0,
  currentPage: 1,
  size: 0,
  total: 0,
  sortBy: '',
};

const pendingStatus = (state) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const rejectResult = (state, action) => {
  if (state.loading === 'pending') {
    state.loading = 'idle';
    state.error = action.error;
  }
};

const boxesSlice = createSlice({
  name: 'boxes',
  initialState,
  reducers: {
    updatePage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    updateSortBy: (state, action: PayloadAction<string>) => {
      state.sortBy = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBoxes.pending, pendingStatus)
      .addCase(fetchBoxes.fulfilled, (state, action) => {
        if (state.loading === 'pending') {
          return { ...state, ...action.payload, loading: 'idle', error: null };
        }
      })
      .addCase(fetchBoxes.rejected, rejectResult);
    // .addCase(syncBoxes.pending, pendingStatus)
    // .addCase(syncBoxes.fulfilled, (state, action) => {
    //   console.log(action.payload);
    // })
    // .addCase(syncBoxes.rejected, rejectResult);
  },
});

export const { updatePage, updateSortBy } = boxesSlice.actions;
export const selectBox = (state: RootState) => state.box;
export default boxesSlice.reducer;
