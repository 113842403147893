import { Form, InputNumber } from 'src/components/form';
import { useAppDispatch, useAppSelector } from 'src/store/hook';
import { MODAL_TYPE, showModal } from 'src/store/modal/modalSlice';
import { SEASON, TOKEN } from 'src/env';
import { selectAuth } from 'src/store/auth/authSlice';
import FormAlert from 'src/components/form/components/FormAlert';
import * as yup from 'yup';
import { selectSeason } from 'src/store/season/seasonSlice';
import FormBuyBoxButton from './FormBuyBoxButton';
import { notExceedMaxBox } from 'src/store/season/seasonApi';
import { seasonContract } from 'src/utils/contracts/contract';
import { customAlphabet } from 'nanoid';
import { getBalance } from 'src/store/auth/authApi';

export type FormValues = {
  amount: number;
};

const defaultValues = { amount: 0 };

const TOKEN_NAME = TOKEN.name;

const FormBuyBox = () => {
  const dispatch = useAppDispatch();
  const { address, loggedIn, balance, noBoxes } = useAppSelector(selectAuth);
  const { price, limitPerTransaction, limitPerUser } = useAppSelector(selectSeason);

  const schema = yup.object({
    amount: yup
      .number()
      .integer()
      .required('Required')
      .min(0, 'The number of boxes cannot be smaller than 0')
      .max(limitPerTransaction, `A user can buy maximum ${limitPerTransaction} boxes at a time.`),
  });

  const onSubmit = async (data: FormValues) => {
    if (!loggedIn) {
      dispatch(showModal({ type: MODAL_TYPE.LOGIN }));
      return;
    }

    if (!(await notExceedMaxBox(address, data.amount))) {
      dispatch(
        showModal({
          type: MODAL_TYPE.FAILED,
          data: { subtitle: `You already have ${noBoxes} boxes. You are not allowed to have more than ${limitPerUser} boxes.` },
        }),
      );
      return;
    }

    const totalAmount = price * data.amount;

    if (balance < totalAmount) {
      dispatch(showModal({ type: MODAL_TYPE.CHECKOUT, data: { amount: totalAmount, gasFee: 0 } }));
      return;
    }

    const tokenIds = Array.from({ length: data.amount }).map(() => {
      return +customAlphabet('1234567890', 8)();
    });

    seasonContract(SEASON.address)
      .methods.buyPackage(SEASON.code, tokenIds)
      .send({ from: address })
      .once('transactionHash', function () {
        dispatch(showModal({ type: MODAL_TYPE.PROCESSING }));
      })
      .once('receipt', async function (receipt) {
        await getBalance(address);
        dispatch(showModal({ type: MODAL_TYPE.COMPLETED, data: { ...receipt, totalAmount } }));
      })
      .on('error', function (e) {
        console.error(e);
        dispatch(showModal({ type: MODAL_TYPE.FAILED, data: { subtitle: 'Transaction failed' } }));
      })
      .catch(function (e) {
        console.error(e);
        dispatch(showModal({ type: MODAL_TYPE.FAILED, data: { subtitle: 'Transaction failed' } }));
      });
  };

  return (
    <Form<FormValues> onSubmit={onSubmit} defaultValues={defaultValues} schema={schema} className="z-10">
      <InputNumber className="mb-12" name="amount" min={0} max={limitPerTransaction} amount={price} />
      <FormBuyBoxButton name="amount" price={price} min={0} max={limitPerTransaction} />
      <FormAlert name="amount" messages={{ notEnough: `Your balance is not enough ${TOKEN_NAME}` }} />
    </Form>
  );
};

export default FormBuyBox;
