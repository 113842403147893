import { FC, useEffect, useState } from 'react';
import { Button } from 'src/components/buttons';
import { ButtonColor } from 'src/components/buttons/Button';
import { MenuItemComponentProps } from '../menuItemComponents';

const MenuItemButton: FC<MenuItemComponentProps> = ({ active, content, onClick, className, children, disabled }) => {
  const [activeType, setActiveType] = useState<ButtonColor>('secondary');

  useEffect(() => {
    setActiveType(active ? 'primary' : 'secondary');
  }, [active]);

  const handleMouseEnter = () => {
    setActiveType('primary');
  };

  const handleMouseLeave = () => {
    // Check if the button is in already active or not
    if (!active) {
      setActiveType('secondary');
    }
  };

  const baseClassName = `menu-item`;
  const extendedClassName = className ? `${baseClassName} ${className}` : baseClassName;

  return (
    <li className={extendedClassName} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Button color={activeType} content={content} onClick={onClick} disabled={disabled}>
        {children}
      </Button>
    </li>
  );
};

export default MenuItemButton;
