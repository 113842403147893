import { FC, ReactNode, useEffect } from 'react';

import imgUrl from 'src/assets/units/gear-1.png';
import ModalBaseDetails from '../../components/ModalBaseDetails';
import ItemContentGear from './components/ItemContentGear';
import ItemContentHero from './components/ItemContentHero';

interface ModalBuyItemsProps {
  type?: 'gear' | 'hero';
  title?: string;
  subtitle?: string;
  content?: string;
  boxImgUrl?: string;
  closable?: boolean;
  children?: ReactNode;
}

const INFO_SUBTITLE = "What's in this box";
const INFO_CONTENT =
  'Vestibulum sodales lorem in erat vehicula commodo. Quisque dolor magna, venenatis eget risus non, auctor facilisis est. Nunc magna nisl, cursus sed posuere eget, facilisis aliquam tortor. Donec eu dapibus eros.';

export type FormValues = {
  amount: number;
};

const defaultProps = { title: 'Thunder', subtitle: INFO_SUBTITLE, content: INFO_CONTENT, boxImgUrl: imgUrl, amount: 500, closable: true };

const ModalBuyItems: FC<ModalBuyItemsProps> = ({ type, content, boxImgUrl, closable }) => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <ModalBaseDetails type={type} title={type === 'gear' ? 'Thunder' : 'Laureano'} size="large" closable={closable} backable itemUrl={boxImgUrl}>
      {type === 'gear' ? <ItemContentGear content={content} /> : <ItemContentHero content={content} />}
    </ModalBaseDetails>
  );
};

ModalBuyItems.defaultProps = defaultProps;
ModalBuyItems.displayName = 'ModalBuyItems';

export default ModalBuyItems;
