import classNames from 'classnames';
import { ButtonBack } from 'src/components/buttons';
import { ButtonColor } from 'src/components/buttons/Button';
import { Alert, Rating } from 'src/components/displays';

import { ModalContentProps } from './ModalContent';

export type ModalBtnType = { content: string; color: ButtonColor; onClick: (e: any) => void };
export type ModalSize = 'large' | 'small';
export type ModalVariant = 'info' | 'error';
export type ModalProps = {
  variant?: ModalVariant;
  size?: ModalSize;
  title?: string;
  type?: 'gear' | 'hero';
  bannerUrl?: string;
  itemUrl?: string;
  closable?: boolean;
  backable?: boolean;
  error?: { message: string };
  className?: string;
} & ModalContentProps;

const defaultProps = { variant: 'info', size: 'small', backable: false, closable: false, overlay: false };

const ModalBaseDetails = ({ type = 'gear', size, variant, title, itemUrl, backable, error, className, children }: ModalProps) => {
  const modalClassName = classNames('modal modal-details', size, variant, className);
  const modalAlertClassName = classNames({ 'mt-14': size === 'large', 'mt-0': size === 'small' });

  return (
    <div className="modal-container">
      {backable && <ButtonBack />}
      <div className={modalClassName}>
        <div className="modal-layout">
          <div className="modal-info-layout gap-20 items-start">
            <div>
              <h1 className="modal-title uppercase flex">
                {title}
                <Rating level={4} className="ml-4" />
              </h1>
              <span className="id-label">#92884483909189</span>
              {type ? (
                <div className="gear-container">
                  <img className="modal-img-item" src={itemUrl} alt={title} />
                </div>
              ) : (
                <img className="modal-img-item" src={itemUrl} alt={title} />
              )}
            </div>
            <div className="modal-info modal-subtitle-left">
              <div className="modal-content owner">
                <h2 className="">Owner:</h2>
                <span>0x4873982792s</span>
              </div>
              {children}
              {error && <Alert type="error" content={error.message} className={modalAlertClassName} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ModalBaseDetails.defaultProps = defaultProps;
ModalBaseDetails.displayName = 'ModalBaseDetails';

export default ModalBaseDetails;
