import classNames from 'classnames';
import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';

export interface TableRowProps extends DetailedHTMLProps<HTMLAttributes<HTMLTableRowElement>, HTMLTableRowElement> {
  className?: string;
}

const TableRow: FC<TableRowProps> = ({ className, children, ...props }) => {
  const trClassName = classNames('table-row', className);

  return (
    <tr className={trClassName} {...props}>
      {children}
    </tr>
  );
};

export default TableRow;
