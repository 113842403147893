import { MARKETPLACE_API_URL } from 'src/env';
import { client } from 'src/utils/api';
import { NftItemsModel } from './models/nftItem.model';

const clientMarketplace = client(MARKETPLACE_API_URL);

const fetchInventories = (params) => clientMarketplace.get('/inventories', { params });

const fetchNftItems = (params): Promise<NftItemsModel> => clientMarketplace.get(`/items`, { params });
const fetchNftItemHistories = (params) => clientMarketplace.get('/item-histories', { params });
const fetchNftItemDetail = ({ id }) => clientMarketplace.get(`/items/${id}`);
const fetchNftItemTransactionFee = ({ id, ...params }) => clientMarketplace.get(`/items/${id}/transaction-fee`, { params });
const fetchNftItemHashMessage = ({ id, ...params }) => clientMarketplace.get(`/items/${id}/hash-message`, { params });

const syncNftItems = () => clientMarketplace.post(`/items/data-sync`);

const fetchSaleNftItems = (params) => clientMarketplace.get('/sale-items', { params });

const nftItemApi = {
  fetchInventories,
  fetchNftItems,
  syncNftItems,
  fetchSaleNftItems,
  fetchNftItemHistories,
  fetchNftItemDetail,
  fetchNftItemTransactionFee,
  fetchNftItemHashMessage,
};

export default nftItemApi;
