import classNames from 'classnames';
import icon from 'src/assets/icons/icon-empty-transaction.png';

interface EmptyTransactionProps {
  className?: string;
  content: string;
}

const EmptyTransaction = ({ className = '', content }: EmptyTransactionProps) => {
  const emptyTransactionClassName = classNames('empty-transaction-container', className);
  return (
    <div className={emptyTransactionClassName}>
      <img alt="Idle Glory" src={icon} />
      <p>{content}</p>
    </div>
  );
};

export default EmptyTransaction;
