import classNames from 'classnames';
import { InputHTMLAttributes, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { selectAuth } from 'src/store/auth/authSlice';
import { useAppSelector } from 'src/store/hook';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  amount?: number;
  className?: string;
}

const InputNumber = ({ type = 'number', amount, min = 0, max = 40, disabled, className, name, ...props }: InputProps) => {
  const { register, watch, setValue, setError, clearErrors } = useFormContext();
  const { balance } = useAppSelector(selectAuth);

  const handleValidateInput = (e) => {
    // Prevent characters that are not numbers ("e", ".", "+" & "-") ✨
    let checkIfNum = false;

    if (e.key !== undefined) {
      // Check if it's a "e", ".", "+" or "-"
      checkIfNum = e.key === 'e' || e.key === '.' || e.key === '+' || e.key === '-';
    } else if (e.keyCode !== undefined) {
      // Check if it's a "e" (69), "." (190), "+" (187) or "-" (189)
      checkIfNum = e.keyCode === 69 || e.keyCode === 190 || e.keyCode === 187 || e.keyCode === 189;
    }

    // Prevent number is over max value
    const isOverMax = +(watch(name) + e.key) > max;

    return (checkIfNum || isOverMax) && e.preventDefault();
  };

  const inputWrapperClassName = classNames('flex', 'item-center', `${className}`);
  const inputClassName = classNames('input', {
    [`input-${type}`]: true,
  });

  const handleDecrease = () => {
    const value = +watch(name);

    if (value > min) {
      const newValue = value - 1;
      setValue(name, newValue);
      validateBalanceEnough(newValue);
    }
  };

  const handleIncrease = () => {
    const value = +watch(name);

    if (value < max) {
      const newValue = value + 1;
      setValue(name, newValue);
      validateBalanceEnough(newValue);
    }
  };

  const handleSetMax = () => {
    setValue(name, max);
    validateBalanceEnough(+max);
  };

  const validateBalanceEnough = (value: number) => {
    if (amount && amount * value > balance) {
      setError(name, { type: 'notEnough' });
    } else {
      clearErrors(name);
    }
  };

  const handleFocus = (event) => event.target.select();

  return (
    <div className={inputWrapperClassName}>
      <div className={inputClassName}>
        <button type="button" className="icon-minus" onClick={handleDecrease} />
        <input type="number" {...register(name)} min={min} max={max} disabled={disabled} onKeyDown={handleValidateInput} onFocus={handleFocus} {...props} />
        <button type="button" className="icon-plus" onClick={handleIncrease} />
      </div>
      <button type="button" className="input-number-max" onClick={handleSetMax}>
        MAX
      </button>
    </div>
  );
};

export default InputNumber;
