import { useNavigate } from 'react-router';
import { TOKEN } from 'src/env';
import { useAppDispatch, useAppSelector } from 'src/store/hook';
import { hideModal, selectModal } from 'src/store/modal/modalSlice';
import { shorten } from 'src/utils/general';
import ModalBase from '../../components/ModalBase';

const ModalCompleted = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { data } = useAppSelector(selectModal);
  const transactionHash = data ? shorten(data?.transactionHash as string) : '';
  const totalAmount = data?.totalAmount as number;

  const infos = [
    {
      name: 'Amount:',
      value: `${totalAmount} ${TOKEN.name}`,
    },
    {
      name: 'Status:',
      value: 'Completed',
    },
    {
      name: 'Transaction ID:',
      value: transactionHash,
    },
  ];

  const goToInventory = () => {
    dispatch(hideModal());
    navigate('/inventory');
  };

  return (
    <ModalBase
      closable
      title="Completed!"
      subtitle={`You successfully purchased. You are about to purchase ${transactionHash}`}
      btns={[{ content: 'Check my Inventory', color: 'primary', onClick: goToInventory }]}
    >
      <table className="modal-table">
        <tbody>
          {infos.map(({ name, value }, index) => (
            <tr key={index}>
              <td>{name}</td>
              <td className="text-right">{value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </ModalBase>
  );
};

ModalCompleted.displayName = 'ModalCompleted';

export default ModalCompleted;
