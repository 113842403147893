import classNames from 'classnames';
import { useFormContext, useWatch } from 'react-hook-form';
interface FilterHeaderProps {
  className?: string;
  onResetFilter?: () => void;
}

const FilterHeader = ({ className, onResetFilter }: FilterHeaderProps) => {
  const filterClassname = classNames('filter-header', className);

  const handleResetFilter = () => {
    if (onResetFilter) {
      onResetFilter();
    }
  };

  return (
    <div className={filterClassname}>
      <h6 className="left-content">Filters</h6>
      <a className="right-content" onClick={handleResetFilter}>
        Reset Filter
      </a>
    </div>
  );
};

FilterHeader.displayName = 'FilterHeader';

export default FilterHeader;
