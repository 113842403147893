import classNames from 'classnames';
import { FC } from 'react';
import { MenuItemComponentProps } from '../menuItemComponents';

const MenuItemDefault: FC<MenuItemComponentProps> = ({ active, onClick, content, disabled, className = '', children }) => {
  const itemClassName = classNames('menu-item menu-item-default', { disabled: disabled }, { className: className }, { active: active });

  const handleOnClick = (e) => {
    if (disabled) {
      e.preventDefault();
    } else {
      onClick(e);
    }
  };

  return (
    <li onClick={handleOnClick} className={itemClassName}>
      {children ? children : <p>{content}</p>}
    </li>
  );
};

export default MenuItemDefault;
