import { useState, useCallback } from 'react';
import { useFormContext, useWatch, Controller } from 'react-hook-form';
import classNames from 'classnames';
import useClickOutside from 'src/hooks/useClickOutside';

interface FilterSelectProps {
  className?: string;
  name: string;
  callback?: () => void;
}

const FilterSelect = ({ className, name, callback }: FilterSelectProps) => {
  const method = useFormContext();
  const watchValue = useWatch({ control: method.control, name });
  const [currentSort, setCurrentSort] = useState(watchValue);
  const { ref, isComponentVisible, setIsComponentVisible } = useClickOutside(false);
  const filterSelectClassName = classNames('filter-select', className);
  const contentContainerClassname = classNames('content-container', { expand: isComponentVisible });

  const handleChangeStatus = useCallback(
    (e) => {
      if (e) {
        e.preventDefault();
      }
      setIsComponentVisible(!isComponentVisible);
    },
    [isComponentVisible],
  );

  const handleChangeSort = useCallback(
    (e) => {
      setCurrentSort(e.target.id);
      method.setValue(name, e.target.id);
      if (callback) {
        callback();
      }
      setIsComponentVisible(!isComponentVisible);
    },
    [isComponentVisible],
  );

  return (
    <Controller
      control={method.control}
      name={name}
      render={({ field: { onChange, onBlur, value, ref } }) => (
        <div className={filterSelectClassName} ref={ref}>
          <div className="title-container">
            <button type="button" id="menu-button" aria-expanded="true" aria-haspopup="true" onClick={handleChangeStatus}>
              <span className="title-left">Sort by:</span>
              <span className="title-right">
                {value}
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
            </button>
          </div>
          {isComponentVisible && (
            <div className={contentContainerClassname} role="menu" aria-orientation="vertical" aria-labelledby="menu-button">
              <a href="#" role="menuitem" id="Lowest ID" onClick={handleChangeSort}>
                Lowest ID
              </a>
              <a href="#" role="menuitem" id="Highed ID" onClick={handleChangeSort}>
                Highed ID
              </a>
              <a href="#" role="menuitem" id="Lowest Price" onClick={handleChangeSort}>
                Lowest Price
              </a>
              <a href="#" role="menuitem" id="Highest Price" onClick={handleChangeSort}>
                Highest Price
              </a>
              <a href="#" role="menuitem" id="Recently Listed" onClick={handleChangeSort}>
                Recently Listed
              </a>
            </div>
          )}
        </div>
      )}
    />
  );
};

FilterSelect.displayName = 'FilterSelect';

export default FilterSelect;
