import classNames from 'classnames';
import { MouseEvent } from 'react';
import useClipboard from 'react-hook-clipboard';
import { toast } from 'react-toastify';

interface ButtonCopyProps {
  className?: string;
  address: string;
}

const ButtonCopy = ({ className, address }: ButtonCopyProps) => {
  const [, setCopied] = useClipboard(address, () => {});
  const iconCopyClassName = classNames('icon-copy', className);

  const handleCopyAddress = (e: MouseEvent<HTMLElement>) => {
    if (e) {
      e.preventDefault();
    }
    setCopied(address, () => {
      toast.info('Copy to Clipboard!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
  };

  return (
    <div>
      <button className={iconCopyClassName} onClick={handleCopyAddress} />
    </div>
  );
};

ButtonCopy.displayName = 'ButtonCopy';

export default ButtonCopy;
