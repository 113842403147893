import imgUrl from 'src/assets/units/character-4.png';
import FormAlert from 'src/components/form/components/FormAlert';
import { TOKEN_2 } from 'src/env';
import { selectAuth } from 'src/store/auth/authSlice';
import { useAppDispatch, useAppSelector } from 'src/store/hook';
import ModalBase, { ModalBtnType } from '../../components/ModalBase';
import * as yup from 'yup';
import { hideModal, MODAL_TYPE, showModal } from 'src/store/modal/modalSlice';
import { Form, InputText } from 'src/components/form';
import { useFormContext } from 'react-hook-form';
import { useState } from 'react';

export type FormValues = {
  amount: number;
};

const defaultValues = { amount: 0 };

const { name: TOKEN_NAME } = TOKEN_2;

const ModalSetPrice = () => {
  const dispatch = useAppDispatch();

  const [onSale, setOnSale] = useState(true);

  const schema = yup.object({
    amount: yup.number().integer().required('Required').min(0, 'The number of boxes cannot be smaller than 0'),
  });

  const onSubmit = async (data: FormValues) => {
    // dispatch(showModal({ type: MODAL_TYPE.COMPLETED, data: { ...receipt, totalAmount } }));
    // if (balance > price) {
    //   dispatch(showModal({ type: MODAL_TYPE.CHECKOUT, data: { amount: price, gasFee: 0 } }));
    //   return;
    // }
    // const tokenIds = Array.from({ length: data.amount }).map(() => {
    //   return +customAlphabet('1234567890', 8)();
    // });
    // marketContract(MARKETPLACE.address)
    // .methods.buyPackage(SEASON.code, tokenIds)
    // .send({ from: address })
    // .once('transactionHash', function () {
    //   dispatch(showModal({ type: MODAL_TYPE.PROCESSING }));
    // })
    // .once('receipt', async function (receipt) {
    //   await getBalance(address);
    //   dispatch(showModal({ type: MODAL_TYPE.COMPLETED, data: { ...receipt, totalAmount: price } }));
    // })
    // .on('error', function (e) {
    //   console.error(e);
    //   dispatch(showModal({ type: MODAL_TYPE.FAILED, data: { subtitle: 'Transaction failed' } }));
    // })
    // .catch(function (e) {
    //   console.error(e);
    //   dispatch(showModal({ type: MODAL_TYPE.FAILED, data: { subtitle: 'Transaction failed' } }));
    // });
  };

  const handleValidateInput = (e) => {
    // Prevent characters that are not numbers ("e", ".", "+" & "-") ✨
    let checkIfNum = false;

    if (e.key !== undefined) {
      // Check if it's a "e", ".", "+" or "-"
      checkIfNum = e.key === 'e' || e.key === '.' || e.key === '+' || e.key === '-';
    } else if (e.keyCode !== undefined) {
      // Check if it's a "e" (69), "." (190), "+" (187) or "-" (189)
      checkIfNum = e.keyCode === 69 || e.keyCode === 190 || e.keyCode === 187 || e.keyCode === 189;
    }

    return checkIfNum && e.preventDefault();
  };

  const handleFocus = (event) => event.target.select();

  const handleCancelListing = () => {};

  const btns = onSale
    ? [
        {
          content: 'Cancel listing',
          color: 'primary',
          compact: true,
          onClick: () => {
            handleCancelListing();
          },
        },
        {
          content: 'Go back',
          color: 'secondary',
          compact: true,
          onClick: () => {
            dispatch(hideModal());
          },
        },
      ]
    : [{ content: 'Post your listing', color: 'primary', className: 'modal-item-btn-buy', compact: true, type: 'submit' }];

  const subtitle = onSale
    ? 'You are about to cancel your listing on marketplace. To sell this item again, you will need to relist this for sale.'
    : 'You are about to list your item on Marketplace #282989019';

  return (
    <ModalBase closable title="List Item" subtitle={subtitle} itemUrl={imgUrl} btns={btns as ModalBtnType[]} className="modal-set-price">
      {!onSale && (
        <Form<FormValues> onSubmit={onSubmit} defaultValues={defaultValues} schema={schema}>
          <InputText type="number" min={0} name="amount" label="Set Price:" prefix={TOKEN_NAME} onKeyDown={handleValidateInput} onFocus={handleFocus} />
          <FormAlert name="amount" messages={{ notEnough: `Your balance is not enough ${TOKEN_NAME}` }} />
        </Form>
      )}
    </ModalBase>
  );
};

ModalSetPrice.displayName = 'ModalSetPrice';

export default ModalSetPrice;
