import { FC, ReactNode } from 'react';
import { InfoBlock, ProgressBar } from 'src/components/displays';
import ModalBase from '../../components/ModalBase';

interface ModalDetailsProps {
  children?: ReactNode;
}

const CONTENT =
  "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever  since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.";

const ModalDetails: FC<ModalDetailsProps> = () => {
  return (
    <ModalBase closable size="large" className="modal-detail">
      <div className="flex flex-row">
        <div className="flex-1"></div>
        <div className="flex-1">
          <div className="max-w-lg">
            <InfoBlock title="Story" content={CONTENT} />
            <InfoBlock title="Stats">
              <ProgressBar type="error" content="base health" value={360} maxValue={360} className="mb-7" />
              <ProgressBar type="success" content="base attack" value={180} maxValue={360} className="mb-7" />
            </InfoBlock>
            <InfoBlock title="Skill">
              <div className="flex flex-row gap-4">
                <div className="w-36 h-36 bg-gray-600" />
                <div className="w-36 h-36 bg-gray-600" />
                <div className="w-36 h-36 bg-gray-600" />
                <div className="w-36 h-36 bg-gray-600" />
              </div>
            </InfoBlock>
          </div>
        </div>
      </div>
    </ModalBase>
  );
};

ModalDetails.displayName = 'ModalDetails';

export default ModalDetails;
