import { FormProvider, useForm } from 'react-hook-form';
import FilterHeader from 'src/components/filters/header/FilterHeader';
import FilterSelect from 'src/components/filters/selects/FilterSelect';
import OutlinedInputField from 'src/components/inputs/OutlinedInputField';
import FilterSwitchBox from 'src/components/filters/selects/FilterSwitchBox';
import FilterRange from 'src/components/filters/range/FilterRange';
import FilterAccordion from 'src/components/filters/accordion/FilterAccordion';
import FilterPrice from 'src/components/filters/price/FilterPrice';
import _ from 'lodash';
// import { DevTool } from '@hookform/devtools';

const listClass = {
  items: [
    { content: 'Assasin1', isActive: false },
    { content: 'Marksman', isActive: false },
    { content: 'Starpunk', isActive: false },
    { content: 'Techie', isActive: false },
  ],
};

const elementClass = {
  items: [
    { content: 'Assasin12', isActive: false },
    { content: 'Marksman2', isActive: false },
    { content: 'Starpunk2', isActive: false },
    { content: 'Techie2', isActive: false },
  ],
};

const Filter = ({ searchParams, setSearchParams }) => {
  const method = useForm({
    defaultValues: searchParams,
  });

  const handleResetFilter = () => {
    setSearchParams({});
    method.reset({
      sort: null,
      text: null,
      listedByMe: null,
      class: [],
      element: [],
      quality: null,
      tiers: null,
      levels: null,
      minPrice: null,
      maxPrice: null,
    });
  };

  const handleSearchParams = () => {
    const values = method.getValues();
    const params = _.keys(values).reduce((result, key) => {
      if (!_.isEmpty(values[key]) || _.isBoolean(values[key]) || (_.isNumber(values[key]) && values[key] > 0)) {
        result[key] = values[key];
      }
      return result;
    }, {});
    setSearchParams(params);
  };

  return (
    <FormProvider {...method}>
      <FilterHeader onResetFilter={handleResetFilter} />
      <FilterSelect name="sort" className="mt-24" callback={handleSearchParams} />
      <OutlinedInputField name="text" className="mt-24" callback={handleSearchParams} />
      <FilterSwitchBox name="listedByMe" className="mt-24" content="LISTED BY ME" isActive={false} callback={handleSearchParams} />
      <FilterAccordion name="class" className="mt-24" content="Class" items={listClass.items} callback={handleSearchParams} />
      <FilterAccordion name="element" className="mt-24" content="Element" items={elementClass.items} callback={handleSearchParams} />
      <FilterRange name="quality" className="mt-24" content="Quality" callback={handleSearchParams} />
      <FilterRange name="tiers" className="mt-24" content="Tiers" callback={handleSearchParams} />
      <FilterRange name="levels" className="mt-24" content="Levels" callback={handleSearchParams} />
      <FilterPrice name="price" className="mt-24" callback={handleSearchParams} />
    </FormProvider>
  );
};

export default Filter;
