import Button, { ButtonProps } from 'src/components/buttons/Button';
import { TOKEN } from 'src/env';

interface BuyButtonProps {
  amount?: number;
  content?: string;
  className?: string;
}

const TOKEN_NAME = TOKEN.name;

const BuyButton = ({ amount, content, className, ...props }: BuyButtonProps & ButtonProps) => {
  const btnContent = content || `PURCHASE ${amount} ${TOKEN_NAME}`;

  const handlePurchase = () => {
    // console.log('handlePurchase');
  };

  return <Button content={btnContent} color="primary" onClick={handlePurchase} className={className} {...props} />;
};

BuyButton.displayName = 'BuyButton';

export default BuyButton;
