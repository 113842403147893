import classNames from 'classnames';
import { DetailedHTMLProps, InputHTMLAttributes } from 'react';
import { useFormContext } from 'react-hook-form';

interface InputTextProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  name: string;
  label?: string;
  prefix?: string;
  type?: string;
  className?: string;
  isVerified?: boolean;
}

const InputText = ({ type = 'text', disabled, className, name, isVerified, label, prefix, ...props }: InputTextProps) => {
  const { register } = useFormContext();

  const inputClassName = classNames({
    input: true,
    [`input-${type}`]: true,
    [`${className}`]: className,
  });

  const emailCheckClassName = classNames('email-check', { verified: isVerified });

  const inputRegister =
    type === 'email'
      ? register(name, {
          required: 'Email is required',
          pattern: {
            value:
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: 'Please enter a valid email',
          },
        })
      : register(name);

  return (
    <>
      {label && <span className="input-label">{label}</span>}
      <div className={inputClassName}>
        {prefix && <span className="input-prefix">{prefix}</span>}
        <input {...inputRegister} disabled={disabled} type={type} {...props} />
        {type === 'email' && (
          <div className={emailCheckClassName}>
            <div className="icon" />
            <p>{isVerified ? 'Verified' : 'Unverified'}</p>
          </div>
        )}
      </div>
    </>
  );
};

export default InputText;
