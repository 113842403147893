import { configureStore, ThunkAction, Action, combineReducers, AnyAction } from '@reduxjs/toolkit';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';

import authReducer, { logout } from './auth/authSlice';
import modalReducer from './modal/modalSlice';
import seasonReducer from './season/seasonSlice';
import boxReducer from './box/boxSlice';

import inventoryReducer from './nft-item/marketplaceSlice';
import marketplaceReducer from './nft-item/marketplaceSlice';

const appReducer = combineReducers({
  auth: authReducer,
  modal: modalReducer,
  season: seasonReducer,
  box: boxReducer,
  inventory: inventoryReducer,
  marketplace: marketplaceReducer,
});

const rootReducer = (state: any, action: AnyAction) => {
  if (action.type === logout.type) {
    storage.removeItem('persist:root');
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
