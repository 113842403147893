import { useAppSelector } from 'src/store/hook';
import { selectSeason } from 'src/store/season/seasonSlice';
import { useTimer } from 'react-timer-hook';

const zeroPad = (num: number, places = 2) => String(num).padStart(places, '0');

const CountdownInProgress = ({ endDate }: { endDate: number }) => {
  const { seconds, minutes, hours, days } = useTimer({
    expiryTimestamp: new Date(endDate),
  });

  return (
    <>
      <span className="countdown-title">In Progress...</span>
      <span className="countdown-timer">
        {zeroPad(days)}:{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
      </span>
    </>
  );
};

const CountdownComing = ({ startDate }: { startDate: number }) => {
  const { seconds, minutes, hours, days } = useTimer({
    expiryTimestamp: new Date(startDate),
  });

  return (
    <>
      <div>
        <span>{zeroPad(days)}</span>
        <span>days</span>
      </div>
      <div>
        <span>{zeroPad(hours)}</span>
        <span>hours</span>
      </div>
      <div>
        <span>{zeroPad(minutes)}</span>
        <span>minutes</span>
      </div>
      <div>
        <span>{zeroPad(seconds)}</span>
        <span>seconds</span>
      </div>
    </>
  );
};

const CountdownEnded = () => <span className="countdown-title uppercase">Event Ended</span>;

const Countdown = () => {
  const { startDate, endDate } = useAppSelector(selectSeason);
  const now = Date.now();

  let renderCountdown = <CountdownInProgress endDate={endDate * 1000} />;

  if (now < startDate * 1000) {
    renderCountdown = <CountdownComing startDate={startDate * 1000} />;
  }

  if (now > endDate * 1000) {
    renderCountdown = <CountdownEnded />;
  }

  return <div className="countdown">{renderCountdown}</div>;
};

export default Countdown;
