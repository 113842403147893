import classNames from 'classnames';
import { FC, ReactNode } from 'react';

export interface ListContainerProps {
  title?: string | ReactNode;
  className?: string;
}

const ListContainer: FC<ListContainerProps> = ({ title, className, children }) => {
  const listContainerClassName = classNames('list-container', className);

  const renderTitle = typeof title === 'string' ? <h6>{title}</h6> : title;

  return (
    <div className={listContainerClassName}>
      {title && renderTitle}
      {children}
    </div>
  );
};

export default ListContainer;
