import _ from 'lodash';
import { List } from 'src/components/displays';

const { Container, Item, ItemAvatar, ItemText, ItemIcon } = List;

export interface RecentListProps {
  items: Record<string, any>[];
}

export const RecentListLevelBadges = ({ items }: RecentListProps) => {
  return (
    <Container title="Recently listed">
      <List>
        {_.map(items, (item, index) => (
          <Item key={index}>
            <ItemAvatar>
              <img src={item.imgUrl} alt={item.id} />
            </ItemAvatar>
            <ItemText primary="ID" secondary={item.id} />
            <ItemText primary="Level" secondary={item.level} />
            <div className="flex items-center">
              {_.map(item.badges, (badge) => (
                <ItemIcon key={badge.id}>
                  <img src={badge.imgUrl} alt={badge.imgUrl} />
                </ItemIcon>
              ))}
            </div>
            <ItemText
              className="currency flex-1"
              primary={item.price.base}
              secondary={
                <div className="flex flex-col">
                  <span>{item.price.toUSD}</span>
                  <span className="caption">{item.price.lastUpdated}</span>
                </div>
              }
            />
          </Item>
        ))}
      </List>
    </Container>
  );
};

export const RecentListBuyerSeller = ({ items }: RecentListProps) => {
  return (
    <Container title="Recently listed">
      <List>
        {_.map(items, (item, index) => (
          <Item key={index}>
            <ItemAvatar>
              <img src={item.imgUrl} alt={item.id} />
            </ItemAvatar>
            <ItemText primary="ID" secondary={item.id} />
            <ItemText primary="Buyer" secondary={item.buyer.address} />
            <ItemText primary="Seller" secondary={item.seller.address} />
            <ItemText
              className="currency flex-1"
              primary={item.price.base}
              secondary={
                <div className="flex flex-col">
                  <span>{item.price.toUSD}</span>
                  <span className="caption">{item.price.lastUpdated}</span>
                </div>
              }
            />
          </Item>
        ))}
      </List>
    </Container>
  );
};
