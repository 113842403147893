import classNames from 'classnames';
import Button, { ButtonProps } from 'src/components/buttons/Button';
import { connect } from 'src/store/auth/authApi';
interface LoginButtonProps {
  className?: string;
}

const LoginButton = ({ className }: LoginButtonProps & ButtonProps) => {
  const loginBtnClassName = classNames('btn-login', className);

  return <Button content="Login" className={loginBtnClassName} onClick={() => connect()} />;
};

LoginButton.displayName = 'LoginButton';

export default LoginButton;
