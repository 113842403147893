import { Container } from 'src/components/layouts';
import { ModalBuyBox } from 'src/components/panels';

const Buy = () => {
  return (
    <Container className="buy">
      <ModalBuyBox closable={false} />
    </Container>
  );
};

export default Buy;
