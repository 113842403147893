import classNames from 'classnames';
import { useState, useCallback } from 'react';
import { connect } from 'src/store/auth/authApi';

interface CheckBoxProps {
  className?: string;
  checked: boolean;
}

const CheckBox = ({ className, checked }: CheckBoxProps) => {
  const [status, setStatus] = useState(checked);
  const checkBoxClassName = classNames('btn-checkbox', className, { checked: status });

  const handleChangeStatus = useCallback(() => {
    setStatus(!status);
  }, [status]);

  return <a className={checkBoxClassName} onClick={handleChangeStatus} />;
};

CheckBox.displayName = 'CheckBox';

export default CheckBox;
