import { useLocation, useNavigate } from 'react-router-dom';

import ItemCard from 'src/components/displays/item-card/ItemCard';

const ListHeroes = ({ items }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const goToDetails = (e, item) => {
    if (e) {
      e.preventDefault();
    }
    navigate(`${pathname}/${item.id}`);
  };

  return (
    <div className="list-heroes">
      {items.map((item) => (
        <ItemCard onClick={(e) => goToDetails(e, item)} key={item.id} color={item.color} src={item.src} name={item.name} star={item.star} price={item.price} />
      ))}
    </div>
  );
};

export default ListHeroes;
