import classNames from 'classnames';
import { ReactNode } from 'react';
import { formatMoney } from 'src/utils/general';

interface ScoreProps {
  className?: string;
  text: ReactNode;
  number: ReactNode;
}

const Score = ({ className, text, number, ...props }: ScoreProps) => {
  const scoreContainerClassName = classNames('score-container', className);

  return (
    <div className={scoreContainerClassName} {...props}>
      <span className="text">{text}</span>
      <span className="number">{formatMoney(number)}</span>
    </div>
  );
};

Score.displayName = 'Score';

export default Score;
