import classNames from 'classnames';

export type RatingProps = {
  size?: 'small' | 'big';
  level?: number;
  className?: string;
};

const Rating = ({ size = 'small', level = 0, className = '' }) => {
  const ratingClassName = classNames('flex justify-center items-center card-header-details-star', className);
  const starSize = size === 'small' ? 'w-8 h-8' : 'w-10 h-10';

  return (
    <div className={ratingClassName}>
      <div className="flex items-center mt-2 mb-1">
        <svg className={`mx-1 w-8 h-8 fill-current ${level >= 1 ? 'text-yellow-400' : 'text-white'}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
          <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" stroke="black" />
        </svg>
        <svg className={`mx-1 w-8 h-8 fill-current ${level >= 2 ? 'text-yellow-400' : 'text-white'}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
          <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" stroke="black" />
        </svg>
        <svg className={`mx-1 w-8 h-8 fill-current ${level >= 3 ? 'text-yellow-400' : 'text-white'}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
          <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" stroke="black" />
        </svg>
        <svg className={`mx-1 w-8 h-8 fill-current ${level >= 4 ? 'text-yellow-400' : 'text-white'}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
          <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" stroke="black" />
        </svg>
        <svg className={`mx-1 w-8 h-8 fill-current ${level >= 5 ? 'text-yellow-400' : 'text-white'}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
          <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" stroke="black" />
        </svg>
      </div>
    </div>
  );
};

export default Rating;
