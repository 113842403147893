import { ReactNode } from 'react';
import { Button } from 'src/components/buttons';
import imgBannerProcessing from 'src/assets/banners/banner-processing.png';
import ModalBase from '../../components/ModalBase';
interface ModalProcessingProps {
  bannerUrl: string;
  children?: ReactNode;
}

const defaultProps = { bannerUrl: imgBannerProcessing };

const ModalProcessing = ({ bannerUrl }: ModalProcessingProps) => {
  return (
    <ModalBase closable size="small" className="modal-processing">
      <div className="modal-layout">
        <img className="modal-banner" src={bannerUrl} alt="Processing" />
        <div className="modal-btn-actions">
          <Button disabled content="Processing..." />
        </div>
      </div>
    </ModalBase>
  );
};

ModalProcessing.defaultProps = defaultProps;
ModalProcessing.displayName = 'ModalProcessing';

export default ModalProcessing;
