import { useState, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import classNames from 'classnames';
import Button from 'src/components/buttons/Button';
import Switch from 'src/components/buttons/Switch';

interface FilterSwitchBoxProps {
  className?: string;
  content: string;
  isActive: boolean;
  name: string;
  callback?: () => void;
}

const FilterSwitchBox = ({ className, content, isActive, name, callback }: FilterSwitchBoxProps) => {
  const [status, setStatus] = useState(isActive);
  const filterSwitchBoxClassName = classNames('filter-switchBox', className);

  return (
    <Button color="primary" className={filterSwitchBoxClassName}>
      {content}
      <Switch name={name} active={status} callback={callback} />
    </Button>
  );
};

FilterSwitchBox.displayName = 'FilterSwitchBox';

export default FilterSwitchBox;
