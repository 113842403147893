import { FC } from 'react';
import { ValidateResult } from 'react-hook-form';

interface AlertProps {
  type?: string;
  content?: string | ValidateResult;
  className?: string;
}

const defaultProps = { type: 'info' };

const Alert: FC<AlertProps> = ({ type, content, className, children }) => {
  const baseClassName = `alert ${type}`;
  const finalClassName = className ? `${baseClassName} ${className}` : baseClassName;

  return (
    <div className={finalClassName}>
      {children ? (
        children
      ) : (
        <>
          <span className="icon-warning" />
          <p className="alert-content">{content}</p>
        </>
      )}
    </div>
  );
};

Alert.defaultProps = defaultProps;
Alert.displayName = 'Alert';

export default Alert;
