import classNames from 'classnames';
import { MouseEvent } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface ButtonBackProps {
  content?: string;
  className?: string;
}

const ButtonBack = ({ content = 'Back', className }: ButtonBackProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const btnBackClassName = classNames('btn-back', className);

  const handleBackToParent = (e: MouseEvent<HTMLElement>) => {
    if (e) {
      e.preventDefault();
    }
    const parentPath = pathname.substring(0, pathname.lastIndexOf('/'));
    navigate(parentPath);
  };

  return (
    <button className={btnBackClassName} onClick={handleBackToParent}>
      <span className="icon-left-arrow" />
      {content}
    </button>
  );
};

ButtonBack.displayName = 'ButtonBack';

export default ButtonBack;
