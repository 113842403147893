import classNames from 'classnames';
import { DetailedHTMLProps, HTMLAttributes, ReactNode } from 'react';

export interface ListItemTextProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  primary?: ReactNode;
  secondary?: ReactNode;
  className?: string;
  children?: ReactNode;
}

const ListItemText = ({ primary, secondary, className, children, ...props }: ListItemTextProps) => {
  const ListItemTextClassName = classNames('list-item-text', className);

  return (
    <div className={ListItemTextClassName} {...props}>
      <span className="primary">{primary || children}</span>
      {secondary && <div className="secondary">{secondary}</div>}
    </div>
  );
};

export default ListItemText;
