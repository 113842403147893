import ModalButton from 'src/components/buttons/ModalButton';
import { BoxModel } from 'src/store/box/box.model';

interface ModalButtonProps {
  boxes: Array<BoxModel> | null;
}

const Boxes = ({ boxes }: ModalButtonProps) => {
  return <div className="boxes">{boxes && boxes.map((item) => <ModalButton key={item.id} content="Not available" />)}</div>;
};

export default Boxes;
