import classNames from 'classnames';
import { MouseEventHandler } from 'react';
import Rating from '../rating/Rating';

interface ItemCardProps {
  className?: string;
  color: string;
  src: string;
  name: string;
  star: number;
  price: number;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

const ItemCard = ({ className, color, src, name, star, price = 11.356, onClick }: ItemCardProps) => {
  const itemCardClassName = classNames('item-card-container', className);
  const cardHeaderClassName = `card-header card-header-${color}`;

  return (
    <div className={itemCardClassName} onClick={onClick}>
      <div className={cardHeaderClassName}>
        <img src={src} alt="Idle Glory" />
        <div className="shadow" />
        <div className="card-header-details">
          <span className="card-header-details-name">{name}</span>
          <Rating level={star} />
        </div>
      </div>
      <div className="card-footer">
        <span className="price">{price} BNB</span>
        <span className="converted-price">$1127.00</span>
      </div>
    </div>
  );
};

ItemCard.displayName = 'ItemCard';

export default ItemCard;
