import boxImgUrl from 'src/assets/boxes/box-idle-1.png';
import { TOKEN } from 'src/env';
import { useAppSelector } from 'src/store/hook';
import { selectModal } from 'src/store/modal/modalSlice';
import ModalBase from '../../components/ModalBase';

const { name: TOKEN_NAME } = TOKEN;

const ModalCheckout = () => {
  const { data } = useAppSelector(selectModal);
  const amount = data?.amount as number;
  const gasFee = data?.gasFee as number;
  const totalPrice = amount + gasFee;

  const infos = [
    {
      name: 'Amount:',
      value: amount,
    },
    {
      name: 'Gas Fee:',
      value: gasFee,
    },
    {
      name: 'Total Price:',
      value: totalPrice,
    },
  ];

  return (
    <ModalBase
      closable
      title="Checkout"
      subtitle="You are about to purchase Glory Box #282989019"
      itemUrl={boxImgUrl}
      error={{ message: `Your balance is not enough ${TOKEN_NAME}` }}
    >
      <table className="modal-table">
        <tbody>
          {infos.map(({ name, value }, index) => (
            <tr key={index}>
              <td>{name}</td>
              <td className="text-right">
                {value} {TOKEN_NAME}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </ModalBase>
  );
};

ModalCheckout.displayName = 'ModalCheckout';

export default ModalCheckout;
