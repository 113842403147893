import classNames from 'classnames';
import { FC, ReactNode } from 'react';

interface InfoBlockProps {
  title?: string;
  content?: ReactNode;
  className?: string;
}

const InfoBlock: FC<InfoBlockProps> = ({ title, content, className, children }) => {
  const infoBlockClassName = classNames('info-block', className);

  return (
    <div className={infoBlockClassName}>
      <div className="info-title">{title}</div>
      {content ? <p className="info-content">{content}</p> : children}
    </div>
  );
};

export default InfoBlock;
