import Web3 from 'web3';
import { AbiItem } from 'web3-utils';

import erc20 from './ERC20.json';
import erc721 from './erc721.json';
import market from './market.json';
import packagz from './packagz.json';
import season from './season.json';

declare let window: any;

export const web3 = new Web3(window.ethereum);
export const MAX_INT = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';

export const erc20Contract = (address?: string) => new web3.eth.Contract(erc20 as AbiItem[], address);
export const erc721Contract = (address?: string) => new web3.eth.Contract(erc721 as AbiItem[], address);
export const marketContract = (address?: string) => new web3.eth.Contract(market as AbiItem[], address);
export const packageContract = (address?: string) => new web3.eth.Contract(packagz as AbiItem[], address);
export const seasonContract = (address?: string) => new web3.eth.Contract(season as AbiItem[], address);
