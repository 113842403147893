import classNames from 'classnames';
import { ReactNode } from 'react';

interface ContainerProps {
  className?: string;
  children?: ReactNode;
}

const defaultProps = { className: '' };

const Container = ({ className, children }: ContainerProps) => {
  const containerClassName = classNames('app-container', className);

  return <div className={containerClassName}>{children}</div>;
};

Container.defaultProps = defaultProps;
Container.displayName = 'Container';

export default Container;
