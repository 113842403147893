import _ from 'lodash';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import EmptyTransaction from 'src/components/displays/empty/EmptyTransaction';
import Pagination from 'src/components/displays/pagination/Pagination';
import { Container } from 'src/components/layouts';
import { selectAuth } from 'src/store/auth/authSlice';
import { useAppDispatch, useAppSelector } from 'src/store/hook';
import { fetchNftItems, selectMarketplace } from 'src/store/nft-item/marketplaceSlice';
import ListHeroes from '../components/list-heroes/ListHeroes';
import Filter from './components/Filter';

const Marketplace = () => {
  const auth = useAppSelector(selectAuth);
  const marketplace = useAppSelector(selectMarketplace);
  const dispatch = useAppDispatch();

  const [searchParams, setSearchParams] = useSearchParams({ sort: 'Recently Listed' });

  const getParams = () => ({
    sort: searchParams.get('sort'),
    text: searchParams.get('text'),
    listedByMe: searchParams.get('listedByMe'),
    class: searchParams.getAll('class'),
    element: searchParams.getAll('element'),
    quality: searchParams.get('quality'),
    tiers: searchParams.get('tiers'),
    levels: searchParams.get('levels'),
    minPrice: searchParams.get('minPrice'),
    maxPrice: searchParams.get('maxPrice'),
  });

  useEffect(() => {
    const handleGetNttItems = (searchParams) => {
      dispatch(fetchNftItems(searchParams));
    };

    handleGetNttItems(getParams());
  }, [searchParams]);

  return (
    <Container className="marketplace">
      <div className="left">
        <Filter searchParams={getParams()} setSearchParams={setSearchParams} />
      </div>
      <div className="right">
        {_.isEmpty(marketplace.items) ? (
          <EmptyTransaction content="No result found" />
        ) : (
          <>
            <ListHeroes items={marketplace.items} />
            <Pagination size={marketplace.pages} className="mt-10" />
          </>
        )}
      </div>
    </Container>
  );
};

export default Marketplace;
