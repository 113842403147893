import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Container } from 'src/components/layouts';
import { CardInfo } from 'src/components/displays';
import { Button } from 'src/components/buttons';
import { Tabs } from 'src/components/panels';

import { selectAuth } from 'src/store/auth/authSlice';
import { useAppSelector, useAppDispatch } from 'src/store/hook';
import { syncBoxes } from 'src/store/box/boxSlice';
import imgUnitOrc from 'src/assets/units/orc.png';
import TabBoxes from '../marketplace/components/TabBoxes';
import TabHeroes from '../marketplace/components/TabHeroes';
import { syncNftItems } from 'src/store/nft-item/marketplaceSlice';

export enum InventoryTab {
  Boxes = 'boxes',
  Heroes = 'heroes',
  Assets = 'assets',
}

const { Tab, TabPanel } = Tabs;

const getValidIntentoryTabs = (searchParams: URLSearchParams) => {
  const activeTab = searchParams.get('tab') as InventoryTab;
  if (activeTab && Object.values(InventoryTab).includes(activeTab)) {
    return activeTab;
  } else {
    return InventoryTab.Boxes;
  }
};

const Menu = () => (
  <div className="menu">
    <Button content="Inventory" className="menu-item" color="primary" />
    <Button content="Activities" className="menu-item" disabled />
    <Button content="Account" className="menu-item" disabled />
    <Button content="Wallet" className="menu-item" disabled />
  </div>
);

const Inventory = () => {
  const auth = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const [loadingSync, setLoadingSync] = useState(false);
  const [activeTab, setActiveTab] = useState<InventoryTab>(getValidIntentoryTabs(searchParams));

  const handleSetActiveItem = (e: any, newValue: string) => {
    if (e) {
      e.preventDefault();
    }
    setActiveTab(newValue as InventoryTab);
    setSearchParams({ tab: newValue });
  };

  const handleSyncData = async () => {
    setLoadingSync(true);
    if (activeTab === InventoryTab.Boxes) {
      await dispatch(syncBoxes());
    } else if (activeTab === InventoryTab.Heroes) {
      await dispatch(syncNftItems());
    }
    setLoadingSync(false);
  };

  return (
    <Container className="inventory">
      <div className="inventory-container">
        <div className="left">
          <CardInfo id={auth.address} name="Anthony93" imgUrl={imgUnitOrc} />
          <Menu />
        </div>
        <div className="right">
          <Tabs
            activeItem={activeTab}
            onClick={handleSetActiveItem}
            customRightComponent={<Button loading={loadingSync} content="Sync" compact className="ml-auto" onClick={handleSyncData} />}
            direction="horizontal"
          >
            <Tab name="boxes" content="Boxes" className="accent" />
            <Tab name="heroes" content="Heroes" />
            <Tab name="assets" content="Assets" disabled />
          </Tabs>
          <TabPanel activeItem={activeTab} index="boxes">
            <TabBoxes loadingSync={loadingSync} />
          </TabPanel>
          <TabPanel activeItem={activeTab} index="heroes">
            <TabHeroes loadingSync={loadingSync} />
          </TabPanel>
          <TabPanel activeItem={activeTab} index="assets">
            Assets
          </TabPanel>
        </div>
      </div>
    </Container>
  );
};

export default Inventory;
