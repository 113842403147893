import ModalBase from '../../components/ModalBase';

const content = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus et nunc sit amet lorem mollis aliquam eget at libero. Vivamus pretium nisl vestibulum augue aliquet rutrum. In hac habitasse platea dictumst. Vestibulum et sollicitudin diam. Suspendisse molestie erat lorem, placerat viverra velit accumsan eget. Aenean tempor lectus neque, vitae suscipit nisi auctor ut. Vivamus feugiat turpis volutpat tellus hendrerit lacinia. Phasellus sed laoreet elit, at pretium neque. Nam egestas nibh vitae nisl ullamcorper egestas. Pellentesque egestas et nisl auctor egestas. Quisque fringilla accumsan vehicula. Fusce suscipit ipsum eget pulvinar tristique. Sed magna sapien, vehicula sed congue ut, pharetra non magna. Aliquam mollis molestie massa et tempus. Maecenas tempor eu ipsum et luctus. Vivamus vitae ultricies odio.

Nam mollis venenatis sapien, commodo dapibus quam maximus at. Praesent commodo lorem metus, nec volutpat nulla dignissim sit amet. Maecenas velit lacus, rutrum lacinia dapibus vehicula, dictum nec mauris. Aenean viverra maximus est at posuere. Quisque ut consectetur orci. Nullam et mauris eleifend urna rutrum eleifend vitae vitae erat. Sed efficitur sodales purus, vitae vehicula libero convallis ut. Phasellus consectetur mattis nisi at eleifend. Suspendisse id fermentum sem, nec blandit lorem.

Morbi at arcu erat. Praesent interdum maximus dolor, quis facilisis purus fringilla vel. Integer eget purus ac dolor auctor hendrerit eget vel mi. Nunc ut urna tempor enim sollicitudin efficitur. Quisque ullamcorper lacus nec nulla tincidunt malesuada. Nam ultrices magna imperdiet velit fermentum, sit amet dapibus lorem aliquam. Mauris vitae mattis nunc, in tincidunt magna. Proin ultrices ultrices nunc vitae congue. Quisque vestibulum odio odio, eu consequat justo dictum at.

Nunc aliquam mi vel bibendum condimentum. Integer quis laoreet quam, eget tincidunt augue. Nullam luctus dolor turpis, et tristique dui facilisis sed. Cras aliquam iaculis arcu, eget tempus magna aliquet et. Praesent viverra finibus ex ac convallis. Vestibulum sagittis, risus eget fermentum varius, ex est porta tortor, faucibus luctus turpis purus quis nisl. Duis maximus ipsum sit amet lorem molestie, sit amet finibus turpis pellentesque.

Cras justo ipsum, molestie sed consectetur quis, consectetur et augue. Vivamus in neque venenatis, ultricies leo id, scelerisque leo. Mauris ultricies ultricies dignissim. Cras dolor massa, consectetur eu volutpat et, rhoncus quis metus. Suspendisse potenti. Integer et mi nec nisl cursus efficitur. Proin sagittis euismod tortor vitae tincidunt. In et pulvinar ligula.

Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus et nunc sit amet lorem mollis aliquam eget at libero. Vivamus pretium nisl vestibulum augue aliquet rutrum. In hac habitasse platea dictumst. Vestibulum et sollicitudin diam. Suspendisse molestie erat lorem, placerat viverra velit accumsan eget. Aenean tempor lectus neque, vitae suscipit nisi auctor ut. Vivamus feugiat turpis volutpat tellus hendrerit lacinia. Phasellus sed laoreet elit, at pretium neque. Nam egestas nibh vitae nisl ullamcorper egestas. Pellentesque egestas et nisl auctor egestas. Quisque fringilla accumsan vehicula. Fusce suscipit ipsum eget pulvinar tristique. Sed magna sapien, vehicula sed congue ut, pharetra non magna. Aliquam mollis molestie massa et tempus. Maecenas tempor eu ipsum et luctus. Vivamus vitae ultricies odio.

Nam mollis venenatis sapien, commodo dapibus quam maximus at. Praesent commodo lorem metus, nec volutpat nulla dignissim sit amet. Maecenas velit lacus, rutrum lacinia dapibus vehicula, dictum nec mauris. Aenean viverra maximus est at posuere. Quisque ut consectetur orci. Nullam et mauris eleifend urna rutrum eleifend vitae vitae erat. Sed efficitur sodales purus, vitae vehicula libero convallis ut. Phasellus consectetur mattis nisi at eleifend. Suspendisse id fermentum sem, nec blandit lorem.

Morbi at arcu erat. Praesent interdum maximus dolor, quis facilisis purus fringilla vel. Integer eget purus ac dolor auctor hendrerit eget vel mi. Nunc ut urna tempor enim sollicitudin efficitur. Quisque ullamcorper lacus nec nulla tincidunt malesuada. Nam ultrices magna imperdiet velit fermentum, sit amet dapibus lorem aliquam. Mauris vitae mattis nunc, in tincidunt magna. Proin ultrices ultrices nunc vitae congue. Quisque vestibulum odio odio, eu consequat justo dictum at.

Nunc aliquam mi vel bibendum condimentum. Integer quis laoreet quam, eget tincidunt augue. Nullam luctus dolor turpis, et tristique dui facilisis sed. Cras aliquam iaculis arcu, eget tempus magna aliquet et. Praesent viverra finibus ex ac convallis. Vestibulum sagittis, risus eget fermentum varius, ex est porta tortor, faucibus luctus turpis purus quis nisl. Duis maximus ipsum sit amet lorem molestie, sit amet finibus turpis pellentesque.

Cras justo ipsum, molestie sed consectetur quis, consectetur et augue. Vivamus in neque venenatis, ultricies leo id, scelerisque leo. Mauris ultricies ultricies dignissim. Cras dolor massa, consectetur eu volutpat et, rhoncus quis metus. Suspendisse potenti. Integer et mi nec nisl cursus efficitur. Proin sagittis euismod tortor vitae tincidunt. In et pulvinar ligula.`;

const ModalTermsOfUse = () => {
  return (
    <ModalBase closable size="large" className="modal-terms-of-use">
      <div className="modal-layout">
        <h6 className="title">TERMS OF USE</h6>
        <p className="content">{content}</p>
      </div>
    </ModalBase>
  );
};

ModalTermsOfUse.displayName = 'ModalTermsOfUse';

export default ModalTermsOfUse;
