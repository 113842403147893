import { SEASON, TOKEN } from 'src/env';
import { showModal, MODAL_TYPE } from 'src/store/modal/modalSlice';
import { useAppDispatch } from './../hook';
import { useState } from 'react';
import { erc20Contract } from 'src/utils/contracts/contract';

const MAX_ALLOWANCE = '115792089237316195423570985008687907853269984665640564039457584007913129639935';

const useApproveAllowance = (userAddress: string | null) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  // const approve = async (amount: number): Promise<void> => {
  //   if (!userAddress) {
  //     dispatch(showModal({ type: MODAL_TYPE.FAILED, data: { subtitle: 'User not identified!' } }));
  //     return;
  //   }

  //   setLoading(true);
  //   const contract = erc20Contract(TOKEN.address);

  //   try {
  //     const weiAmount = +web3.utils.toWei(`${amount}`, TOKEN_UNIT);
  //     await contract.methods.approve(SEASON.address, `${weiAmount}`).send({ from: userAddress });
  //   } catch (e: any) {
  //     dispatch(showModal({ type: MODAL_TYPE.FAILED, data: { subtitle: e.message } }));
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const approveMax = async (): Promise<void> => {
    if (!userAddress) {
      dispatch(showModal({ type: MODAL_TYPE.FAILED, data: { subtitle: 'User not identified!' } }));
      return;
    }

    setLoading(true);
    const contract = erc20Contract(TOKEN.address);

    console.log(MAX_ALLOWANCE);

    try {
      await contract.methods.approve(SEASON.address, MAX_ALLOWANCE).send({ from: userAddress });
    } catch (e: any) {
      dispatch(showModal({ type: MODAL_TYPE.FAILED, data: { subtitle: e.message } }));
    } finally {
      setLoading(false);
    }
  };

  return [approveMax, loading] as const;
};

export default useApproveAllowance;
